export default {
  "CheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заселение"])},
  "IBAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
  "Search for address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск адреса"])},
  "When": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда"])},
  "aboutTheExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше информации"])},
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принимать"])},
  "accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принял"])},
  "access": {
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ"])},
    "byCar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На машине"])},
    "byPublicTransport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На общественном транспорте"])},
    "byTourBus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На экскурсионном автобусе"])},
    "isHardSurfacedRoad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дорога с твердым покрытием"])},
    "isSignPosted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направляющие или дорожные знаки"])},
    "onFoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пешком"])}
  },
  "activation": {
    "activateUserError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активация пользователя не удалась"])},
    "activateUserSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь успешно активирован"])},
    "invalidActivationCodeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный код активации"])}
  },
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлять"])},
  "addAnotherDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить скидку"])},
  "addAnotherHousingUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить жилую единицу"])},
  "addAnotherPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить еще один период"])},
  "addAnotherPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить другую цену"])},
  "addAnotherUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить другого пользователя"])},
  "addAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вложения"])},
  "addBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить компанию"])},
  "addNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новое"])},
  "addNewBusiness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новый бизнес"])},
  "addNewClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить нового клиента"])},
  "addNewResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новый ресурс"])},
  "addNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить новую задачу"])},
  "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в корзину"])},
  "addToCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно добавлено в корзину"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавлен"])},
  "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата добавления"])},
  "additionalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
  "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные услуги / продукты"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "address_": {
    "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строительство"])},
    "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["округ"])},
    "farmApartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ферма/квартира"])},
    "villageSuburbTown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деревня/пригород/город"])}
  },
  "admin": {
    "companyListPage": {
      "filters": {
        "showTestCompanies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать поставщиков услуг тестирования"])}
      },
      "headers": {
        "isTestCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщики услуг тестирования"])},
        "lastUserLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний Войти"])},
        "publishedServicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликованные статьи о продажах"])},
        "servicesCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продающие статьи"])},
        "usersCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])}
      },
      "updateIsTestCompanyErrorMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обновление статуса проверки поставщика услуг \"", _interpolate(_named("company")), "\" не удалось:"])},
      "updateIsTestCompanySuccessMessage": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Статус проверки поставщика услуг \"", _interpolate(_named("company")), "\" успешно обновлен."])}
    },
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Админ"])},
    "postponedRefundsPage": {
      "createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])},
      "retryButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторить"])},
      "retryRefundErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторная попытка возврата не удалась"])},
      "retryRefundSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторная попытка возврата прошла успешно"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отложенные возвраты"])}
    },
    "tabs": {
      "commissionReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет комиссии"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозитный отчет"])},
      "providerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщики услуг"])}
    }
  },
  "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взрослые"])},
  "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удобства"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Квартира"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архив"])},
  "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
  "arrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата прибытия"])},
  "attendants:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько людей:"])},
  "automatically": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматически"])},
  "availableTickets": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Осталось ", _interpolate(_named("maxGroupSize")), " доступных билетов"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "baseMultimedia": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить, название, изменить порядок отображения, удалить изображения."])}
  },
  "basicInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Основная информация"])},
  "bed_": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кровать"])},
  "beds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кровати"])},
  "bedsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кровать(и)"])},
  "beneficialOwners": {
    "beneficialOwners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенефициарные владельцы"])},
    "beneficialOwnersLocationInAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Более подробную информацию о бенефициарных владельцах можно найти в статье 9 следующего Закона."])},
    "linkToAct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закон Эстонии о предотвращении отмывания денег и финансирования терроризма"])},
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенефициарные владельцы не указаны."])},
    "whyWeCollectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Touringery собирает информацию о бенефициарных владельцах поставщиков услуг в соответствии с местным законодательством и требованиями наших процессоров транзакций."])}
  },
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выставление счетов"])},
  "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата рождения"])},
  "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бухгалтерский учет"])},
  "book_keeping": {
    "goToTrigonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в бухгалтерскую программу Trigon"])}
  },
  "bookingCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование успешно создано"])},
  "bookingCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать бронирование"])},
  "bookingForm": {
    "buttons": {
      "addBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить бронирование"])}
    },
    "clientSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о клиенте"])}
    },
    "editExistingClientAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы обновляете контактную информацию существующего клиента вместо создания нового.\n\nЧтобы создать нового клиента, вы должны убрать существующий клиент из списка."])},
    "fields": {
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
      "participants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество гостей"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажная статья"])},
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свидание"])},
      "timeslotDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность бронирования"])},
      "timeslotStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало бронирования"])},
      "timeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])}
    },
    "loadingDatePickerTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступность загрузки"])},
    "noTimeslots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свободные временные интервалы не найдены"])},
    "removeClientLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить клиент"])},
    "serviceSection": {
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажная статья"])}
    },
    "timeslotsNotLoaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите статью о продаже, а затем дату, чтобы увидеть свободные временные интервалы."])}
  },
  "bookingIntegration": {
    "activateConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать соединение"])},
    "activateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировать соединения комнат"])},
    "bookingAgreeText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я понимаю и согласен с ограничениями интеграции и ее влиянием на канал Booking.com."])},
    "bookingProperty": {
      "checkPropertyButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить статус подключения"])},
      "refreshRoomRatesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление номеров и тарифных планов"])}
    },
    "cancelConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить соединение"])},
    "cancelRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить соединение комнат"])},
    "closedRestrictionsCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я проверил свои закрытые ограничения на Booking.com."])},
    "completedErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция с Booking.com не удалась. \nОтмените подключение и повторите попытку или обратитесь в нашу службу поддержки."])},
    "completedSuccessMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Интеграция с Booking.com успешно завершена. \n", _interpolate(_named("new_reservations_created")), " созданы новые бронирования"])},
    "connectRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключиться к комнатам"])},
    "connectToPropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключиться к объекту размещения"])},
    "connecting": {
      "activatePropertyFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь, когда необходимые шаги в Booking.com выполнены, пришло время начать подключение и картографирование с нашей стороны."])},
      "activatePropertySecondText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прежде всего необходимо создать связь с вашим объектом на сайте booking.com. \nДля этого вам необходимо ввести свой идентификатор объекта на сайте booking.com и нажать «Активировать соединение»."])},
      "activatePropertyTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связь с недвижимостью"])},
      "activateRoomsFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После того, как соединение активировано, пришло время сопоставить комнаты вашего объекта с соответствующими помещениями в Туринжери. \nЧтобы активировать подключение жилья, вам необходимо нанести на карту все указанные номера на сайте booking.com."])},
      "activateRoomsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Картографические комнаты"])},
      "activeConnectionFirstText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После успешного сопоставления и подключения номеров Touringery начнет синхронизировать данные о доступности, ценах и ограничениях с сайтом booking.com. \nСкорость синхронизации зависит от общей активности интеграции и количества обновлений, необходимых для синхронизации всех подключений."])},
      "activeConnectionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активное соединение"])},
      "closedRestrictionsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перед настройкой интеграции необходимо снять существующие закрытые ограничения. \nЭто необходимо, поскольку API не позволяет нам удалить ранее закрытые ограничения, и их невозможно будет удалить через календарь Booking.com при активации поставщика услуг подключения."])},
      "closedRestrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытые ограничения"])},
      "connectToConnectivityProviderLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка и работа с провайдером связи"])},
      "connectivityProviderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первое, что необходимо сделать, — это подключиться к нашему поддерживаемому поставщику услуг связи на Booking.com."])},
      "connectivityProviderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщик подключения"])},
      "connectivityProviderZodomusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя поддерживаемого поставщика услуг связи — «Zodomus»."])},
      "connectivityTutorialLinkText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаги, которые необходимо для этого предпринять, описаны в следующей статье Booking.com"])},
      "introText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для этой интеграции мы ожидаем, что у вас будет существующий объект Booking.com с номерами и стоимостью номеров."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как подключиться"])}
    },
    "deleteConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить соединение"])},
    "description": {
      "centralizedToTouringeryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта интеграция позволяет вам управлять доступностью, ценами и ограничениями вашего объекта недвижимости через Touringery."])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])}
    },
    "housingHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подходящий корпус Touringery из списка"])},
    "limitations": {
      "bookingReservationsFunctionalityText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирования, сделанные на Booking.com, появятся в календаре Touringery. \nМожно будет увидеть некоторую основную информацию о клиенте. \nТакже будут применяться шаблоны писем и функции регистрации."])},
      "durationPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения цен на основе продолжительности не синхронизируются с Booking.com. \nЭто влияет только на цены в Туринжери."])},
      "initialConnectionUpdatesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наибольшее количество обновлений требуется при первоначальном подключении, поскольку на этом этапе необходимо синхронизировать все цены и доступность. \nКоличество обновлений зависит от сложности ценовых периодов вашего подключенного жилья и существующего количества бронирований в Touringery."])},
      "numberOfUpdatesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество обновлений"])},
      "priceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
      "reservationsLimitedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые и существующие будущие бронирования будут синхронизированы с Booking.com с Touringery, но их функциональность в нашей системе будет ограничена."])},
      "reservationsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
      "standardPriceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полные цены, указанные в ценовых периодах нашего жилья, будут установлены в качестве стандартной цены в тарифах на номера Booking.com."])},
      "standardPricingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking.com предлагает несколько типов цен, из которых мы поддерживаем только стандартную модель ценообразования."])},
      "syncBookingReservationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С другой стороны, обновления, касающиеся бронирований, сделанных на Booking.com, обрабатываются отдельно и не зависят от длины общей очереди."])},
      "syncOnDemandText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После этого синхронизация выполняется при внесении соответствующих изменений в Touringery. \nК ним относятся, помимо прочего, изменения сроков проживания, соответствующие предпочтения, соответствующее бронирование и закрытие."])},
      "syncingFrequencyText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация изменений Touringery с Booking.com не происходит мгновенно. \nВсе обновления попадают в общую очередь, а это значит, что время, необходимое для обновления вашего объекта Booking.com, зависит от позиции, которую занимают в нем ваши обновления."])},
      "syncingTimeTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота обновления"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])}
    },
    "propertyActivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при активации свойства"])},
    "propertyActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурс успешно активирован"])},
    "propertyCancellationnError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка отмены соединения"])},
    "propertyCancellationnSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соединение успешно отменено"])},
    "propertyHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы начать интеграцию с Booking.com, сначала введите идентификатор объекта в booking. \nВы можете найти это в заголовке сайта администратора booking.com рядом с логотипом Booking."])},
    "propertyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор свойства"])},
    "roomIdLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор комнаты Booking.com"])},
    "roomsActivationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соединения с корпусом успешно активированы"])},
    "roomsctivationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при активации соединений корпуса"])},
    "statusActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активировано"])},
    "statusError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "statusInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивный"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер канала Booking.com"])},
    "updateRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить соединения комнат"])},
    "zodomusConnectedCheckboxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я выбрал Zodomus в качестве поставщика услуг связи на Booking.com."])}
  },
  "bookingWidget": {
    "addToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить в корзину"])},
    "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
    "chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрано"])},
    "errors": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, убедитесь, что выбранная дата/временной промежуток доступен"])},
      "consecutiveSlots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, убедитесь, что выбранный временной промежуток является последовательным"])},
      "slotsMaxDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пожалуйста, убедитесь, что выбранный временной промежуток не превышает ", _interpolate(_named("maxDuration"))])},
      "slotsMinDuration": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Пожалуйста, убедитесь, что выбранный временной промежуток составляет не менее ", _interpolate(_named("minDuration"))])}
    },
    "goToBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к покупке"])},
    "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер группы"])},
    "noAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет доступности на этот период"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет результатов"])},
    "pickService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите услугу, чтобы увидеть доступность"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "whatWillYouBuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что вы хотите купить?"])},
    "wholeDay": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " (весь день)"])}
  },
  "bookingsCalendar": {
    "onlyTransactionsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать только с транзакциями"])},
    "reservationDetails": {
      "guest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гость"])},
      "openDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открытое бронирование"])},
      "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Происхождение бронирования"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особые запросы"])},
      "seeAllBookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть все бронирования от этого гостя"])},
      "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итоговая цена"])}
    }
  },
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строительство"])},
  "businessDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание деятельности"])},
  "businessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сведения о компании"])},
  "businessName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование фирмы"])},
  "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
  "cancelBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Отмена бронирования"])},
  "cancelMessageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете описать причину отмены"])},
  "cancelOrder": {
    "cancelServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при отмене заказа."])},
    "cancelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ успешно отменен"])},
    "cannotCancelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно отменить этот заказ в данный момент"])},
    "confirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите отменить этот заказ?"])},
    "confirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить заказ"])},
    "removeOrderItemConfirmationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить этот товар из заказа?"])},
    "removeOrderItemConfirmationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить из заказа"])},
    "removeOrderItemServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка удаления товара из заказа"])},
    "removeOrderItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно удалено из заказа"])}
  },
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вместимость"])},
  "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина"])},
  "cartOverview": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Обзор заказа №#", _interpolate(_named("referenceNumber"))])},
  "cartPage": {
    "cartConfirmErrorAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при подтверждении корзины"])},
    "cartConfirmSuccessAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина успешно подтверждена."])},
    "cartConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Корзина подтверждена, срок ее действия истекает в ", _interpolate(_named("expiresAt")), "."])},
    "clientConfirmedOrderExpiresAtAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Информация о клиенте была подтверждена в дополнение к корзине, и срок ее действия истекает в ", _interpolate(_named("expiresAt")), "."])},
    "noItemsInCartText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина пуста"])},
    "orderStatusCancelledAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ отменен."])},
    "orderStatusPaidAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ в статусе оплачен. \nСкачать билеты."])},
    "orderStatusPaidAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ в статусе оплачен."])},
    "orderStatusPendingAlertWithTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция успешно завершена. \nокончательное подтверждение будет доставлено на электронную почту с задержкой. \nСкачать билеты."])},
    "orderStatusPendingAlertWithoutTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакция успешно завершена. \nокончательное подтверждение будет доставлено на электронную почту с задержкой."])},
    "orderStatusTimedOutAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия заказа истек."])}
  },
  "categories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категории"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])},
  "ceoOrBoardMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исполнительный директор или член правления"])},
  "changeHousingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить данные о жилье"])},
  "channel": {
    "activateChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соединение с каналом успешно установлено."])},
    "activateRoomsSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комнаты собственности успешно подключены."])},
    "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал"])}
  },
  "channels": {
    "activateChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось подключиться к каналу."])},
    "activatePropertyRoomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объединение комнат"])},
    "activateRoomsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось соединить помещения объекта."])},
    "booking-widget-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный вид"])},
    "bookingActivation": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый канал Booking.com"])}
    },
    "bookingProperty": {
      "checkPropertyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить статус подключения."])},
      "checkPropertySuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус подключения обновлен."])},
      "refreshRoomRatesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить номера в отеле и соответствующие тарифные планы."])},
      "refreshRoomRatesSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номера и соответствующие тарифные планы успешно обновлены."])}
    },
    "bookingPropertyChannelTitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Недвижимость ", _interpolate(_named("property"))])},
    "channel-manager-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер каналов"])},
    "confirmPropertyDeactivationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите деактивировать этот канал?"])},
    "connectPropertyChannelLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соединять"])},
    "connectRoomsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы объединить номера Booking.com в вашем объекте, вам необходимо выбрать их стандартный тарифный план и соответствующий артикул жилья."])},
    "deleteChannelError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить канал."])},
    "deleteChannelSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Канал успешно удален."])},
    "fetchPropertyChannelDetailsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить сведения о канале."])},
    "header": {
      "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор объекта"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы не найдены."])},
    "noPropertyRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В объекте не найдено ни одного номера."])},
    "propertyConnectionNeededForRoomsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номера будут показаны после подключения к объекту размещения."])},
    "ratePlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифный план"])},
    "roomHousingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество квартир в выбранной статье жилья должно быть равно или меньше количества комнат. \nВ противном случае при попытке синхронизировать более высокую доступность, чем принимает Booking.com, будут выданы ошибки."])},
    "roomRatePlanInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тарифный план, который необходимо обновить на основе данных Touringery. \nПоддерживаются только стандартные тарифные планы."])},
    "roomTitleWithNameAndId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор комнаты"])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
      "ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивный"])},
      "PROPERTY_ACTIVATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично подключено"])}
    },
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный вид"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["каналы"])}
  },
  "checkAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск доступности"])},
  "checkIn": {
    "aboutClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О клиенте"])},
    "aboutClientStaying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если бронирование приобретено для других лиц, не выбирайте следующий вариант. \nЕсли вы бронируете несколько номеров, выберите следующий вариант только для того бронирования, в котором вы планируете остановиться."])},
    "aboutTravelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните форму для всех, кто путешествует с вами"])},
    "aboutTrip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные о путешествии"])},
    "aboutYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши данные"])},
    "addTravelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить попутчика"])},
    "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительный вопрос"])},
    "additionals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительный вопрос"])},
    "additionalsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете сформулировать дополнительные вопросы, которые будут добавлены в форму регистрации на выбранные услуги. \nОтвет: текстовое поле длиной не более 1000 символов."])},
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взрослый"])},
    "ageGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возрастная группа"])},
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При изменении вопроса имейте в виду, что он меняется ретроспективно и на уже сохраненные вопросы."])},
    "checkInDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать подробности"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ребенок"])},
    "confirmCorrect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я подтверждаю, что предоставленные данные верны"])},
    "detailsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробная информация о вашей поездке"])},
    "downloadForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать форму"])},
    "estimatedCheckInTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расчетное время заезда"])},
    "groupSizeHasChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Количество посетителей изменилось, вместо исходных ", _interpolate(_named("originalCount")), " указано ", _interpolate(_named("guestsPlural")), ". Это может привести к изменениям в обслуживании."])},
    "hoursRange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["В период ", _interpolate(_named("check_in_hours_range"))])},
    "housingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали жилья"])},
    "isClientStayingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент проживает в этом бронировании"])},
    "isForConferenceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участие в конференции"])},
    "isNotSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, отправьте данные и сохраните регистрационную форму"])},
    "isSubmittedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма отправлена"])},
    "isWrongReservationTypeAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно отправить форму с текущим статусом бронирования"])},
    "openCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в корзину"])},
    "purpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель поездки"])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка сохранения данных"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
    "serviceSelectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите продающую статью, чтобы увидеть соответствующие вопросы"])},
    "statusSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поданный"])},
    "statusUnSubmitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не отправлено"])},
    "tavelCompanion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попутчик"])},
    "tavelCompanions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попутчики"])},
    "travelData": {
      "clientHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент"])}
    },
    "travelPurpose": {
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другой"])}
    },
    "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпуск"])},
    "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])}
  },
  "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дети"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
  "chooseCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите категорию"])},
  "chooseSubCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите подкатегорию"])},
  "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гражданство"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Город/муниципалитет"])},
  "cityRegion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деревня/Город/Район"])},
  "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чистый"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["клиент"])},
  "clientBookings": {
    "tableHeaders": {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свидание"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гости"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
      "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статья о продаже"])},
      "source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])}
    }
  },
  "clientCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент успешно создан"])},
  "clientCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать клиент"])},
  "clientInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о клиенте"])},
  "clientSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о клиенте сохраняется"])},
  "clientSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить информацию о клиенте"])},
  "clientTab": {
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выставление счетов"])},
    "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
    "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обратная связь"])}
  },
  "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
  "clients_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя клиента"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыто"])},
  "color": {
    "blue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синий"])},
    "blue_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бирюзовый"])},
    "blue_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиолетовый"])},
    "green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зеленый"])},
    "orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апельсин"])},
    "purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиолетовый"])},
    "red": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Красный"])},
    "red_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["киноварь"])},
    "red_purple": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пурпурный"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет"])},
    "yellow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Желтый"])},
    "yellow_green": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шартрез"])},
    "yellow_orange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янтарь"])}
  },
  "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "commentUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось добавить комментарий"])},
  "commentUpdateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий добавлен!"])},
  "companyCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания успешно создана"])},
  "companyCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать компанию"])},
  "companyLocation": {
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить это местоположение?"])},
    "createLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать местоположение."])},
    "createLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать местоположение"])},
    "createLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местоположение успешно создано"])},
    "deleteLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить местоположение."])},
    "deleteLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местоположение успешно удалено"])},
    "fetchLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить местоположение."])},
    "generalBlockTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация"])},
    "updateLocationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить местоположение."])},
    "updateLocationModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить местоположение"])},
    "updateLocationSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местоположение успешно обновлено"])}
  },
  "companyLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип компании"])},
  "companyNotifications": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления компании"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка автоматических уведомлений по электронной почте, отправляемых на электронную почту компании."])},
    "notification": {
      "eventCancelDelayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена мероприятия отложена"])},
      "eventCancelDelayedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется каждый раз, когда автоматическая отмена события временно задерживается. \nПричиной задержки может быть незавершенный платеж."])},
      "eventCancelPassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достигнуто минимальное количество участников мероприятия"])},
      "eventCancelPassedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется, когда при проверке автоматической отмены было достигнуто минимальное количество участников мероприятия. \nЭто означает, что автоматическая отмена для данного события будет отключена."])},
      "eventCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие отменено"])},
      "eventCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется каждый раз, когда мероприятие автоматически отменяется"])},
      "orderPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый заказ"])},
      "orderPaidDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется каждый раз, когда товары поставщика услуг оплачены, т. е. создается новый заказ."])},
      "partnerInviteResponse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ на приглашение к партнерству"])},
      "partnerInviteResponseDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется, когда ваше приглашение о партнерстве в компанию принято."])},
      "partnerPayoutChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение процента партнерских выплат"])},
      "partnerPayoutChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется, когда партнер изменил процент выплат."])},
      "partnerStatusChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение статуса партнерства"])},
      "partnerStatusChangeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется при изменении статуса партнерства"])},
      "reservationCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование отменено"])},
      "reservationCancelledDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляется каждый раз, когда клиент отменяет бронирование."])}
    },
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить электронное письмо"])}
  },
  "companyRole": {
    "addRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить роль"])},
    "alertText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль администратора не может быть изменена или удалена. \nВ компании должен быть хотя бы один человек с ролью администратора."])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавать новое"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать"])},
    "removeRoleConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить эту роль?"])},
    "write": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновлять"])}
  },
  "companyRoles": {
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "permissios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения"])}
  },
  "compucash": {
    "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя клиента с стороны Compucash"])},
    "clientSecret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Секрет клиента с стороны Compucash"])},
    "dataIsStillSyncing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные Compucash всё ещё синхронизируются, пожалуйста, зайдите позже."])},
    "deleteProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Удалить продукт Compucash ", _interpolate(_named("productId")), " - ", _interpolate(_named("productName"))])},
    "deleteProductConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить этот продукт?"])},
    "deleteProductInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это действие удаления удаляет только данные продукта Compucash, сохраненные в Touringery, но НЕ удаляет связанный с ним сервис Touringery."])},
    "doubleCheckCompucashData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, внимательно проверьте данные интеграции Compucash. Для получения дополнительной информации свяжитесь с Touringery или Compucash."])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные, необходимые для интеграции с Compucash, чтобы синхронизировать продукты из Compucash в Touringery. Для получения необходимых значений, пожалуйста, свяжитесь с Compucash."])},
    "makeIntoService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить к услуге"])},
    "makeIntoServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подключить продукты Compucash к услугам"])},
    "newServiceForCompucashProduct": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Новая услуга для продукта Compucash ", _interpolate(_named("productId"))])},
    "productDeprecatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний ответ синхронизации Compucash не содержал этот продукт, возможно, потому что он был удален или продажа через интернет была запрещена. Связанная услуга была установлена как частная, чтобы предотвратить любые прямые продажи. Если услуга больше не требуется, вы можете архивировать ее в настройках сервиса. Если это ошибка, пожалуйста, проверьте продукт в Compucash."])},
    "productID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID продукта"])},
    "productName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название продукта"])},
    "serviceSettingsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки продукта Compucash"])},
    "serviceSettingsHeaderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если поставщик услуг подключен к Compucash, здесь можно связать уже опубликованную статью продажи с продуктом в Compucash."])},
    "serviceSettingsUserAddedRelatedCompucashId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор продукта в Compucash"])},
    "serviceSettingsUserAddedRelatedCompucashIdInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это идентификатор продукта Compucash, используемый для синхронизации существующего продукта в Touringery с Compucash вместо создания нового."])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["статус"])},
    "sync": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизировать"])},
    "syncFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация с Compucash не удалась. Пожалуйста, свяжитесь с Touringery."])},
    "syncFinishedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация с Compucash успешно завершена."])},
    "syncStarted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация начата"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки интеграции с Compucash"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемый НДС"])},
    "withoutServiceToggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать только товары без услуги"])}
  },
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждать"])},
  "confirmAndPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oплатить"])},
  "confirmDialog": {
    "areYouSure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])}
  },
  "confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение"])},
  "confirmationEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Письмо о бронировании успешно отправлено"])},
  "confirmationEmailSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникли проблемы при отправке письма"])},
  "contactServiceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактная информация"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжать"])},
  "coordinates": {
    "DD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДД (десятичная степень)"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координаты"])}
  },
  "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано!"])},
  "copyUrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать URL"])},
  "countdown": {
    "inPast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в прошлом"])}
  },
  "countryOfResidence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна пребывания"])},
  "county": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["округ"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создавать"])},
  "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрироваться"])},
  "createBulk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать пакет"])},
  "createNewResourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новую группу"])},
  "createServiceDraftError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать черновик статьи о продаже."])},
  "currentCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Текущий"])},
  "customerLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте ярлыки клиентa"])},
  "customerLabelsAndComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ярлыки и комментарии клиентa"])},
  "customerRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рейтинг клиентов"])},
  "dataIsSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные сохраняются"])},
  "dataLoadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "dataSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить данные"])},
  "dataSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные успешно сохранены"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свидание"])},
  "dayNoHourOption": {
    "fromTheStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["начало дня"])},
    "untilEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["конец дня"])}
  },
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дни"])},
  "deadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крайний срок"])},
  "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задерживать"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
  "deleteClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить клиента"])},
  "deleteResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить ресурс"])},
  "deleteTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить задачу"])},
  "departure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата отбытия"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности"])},
  "disableTrackList": {
    "addItemModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое закрытие"])}
  },
  "disabledTrackItem": {
    "allExcludedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "confirmDeleteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены?"])},
    "deleteTrackErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить замыкание."])},
    "deleteTrackSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие успешно удалено."])},
    "exclusionTypeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип закрытия"])},
    "form": {
      "endLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец (исключено)"])},
      "startLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старт (включено)"])}
    },
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие"])},
    "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "trackType": {
      "fullDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть всех гостей"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть все блоки"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть все"])}
      },
      "quantityDisable": {
        "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть некоторых гостей"])},
        "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть некоторые блоки"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закройте некоторые"])}
      }
    },
    "updateItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить закрытие."])},
    "updateItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие успешно обновлено."])},
    "updateModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление закрытия"])}
  },
  "disabledTrackList": {
    "addItemButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить закрытие"])},
    "createItemErrorToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать замыкание."])},
    "createItemSuccessToast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытие успешно создано."])},
    "experience": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гости"])}
    },
    "housing": {
      "quantityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилые единицы"])}
    },
    "noItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никаких замыканий."])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрытия"])}
  },
  "discount": {
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возраст"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка"])},
    "inDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В днях"])},
    "inHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В часах"])},
    "numOfDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько дней"])},
    "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько гостей"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
    "untilDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (количество дней)"])},
    "untilGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" (количество гостей)"])},
    "untilSumLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(сколько)"])},
    "untilYearLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Сколько лет)"])}
  },
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидки"])},
  "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расстояние"])},
  "distanceToStop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расстояние до остановки"])},
  "documentExpDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия документа"])},
  "documentNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер документа"])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделанный"])},
  "downloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать счет"])},
  "downloadTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать билет"])},
  "downloadTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать билеты"])},
  "dragHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["или перетащите сюда"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублировать"])},
  "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
  "durationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность вручную"])},
  "durationPicker": {
    "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])}
  },
  "durations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность"])},
  "dynamicNameTotal": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " всего"])},
  "dynamicNight": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["ночей"]), _normalize(["ночь"]), _normalize(["ночи"])])},
  "dynamicNightWithCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ночь"]), _normalize([_interpolate(_named("count")), " ночь"]), _normalize([_interpolate(_named("count")), " ночей"])])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменять"])},
  "editCartSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменено успешно"])},
  "editUsers": {
    "changeRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить роль пользователя"])},
    "editUsersLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пользователя"])},
    "goToRolesView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти к просмотру ролей"])},
    "resendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить приглашение повторно"])}
  },
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эл. почта"])},
  "emailNotificationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланированные электронные письма"])},
  "end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец"])},
  "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания"])},
  "erfLogo": {
    "altText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип Европейского фонда регионального развития"])}
  },
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
  "eventCalendarDetails": {
    "eventReservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
    "noEventPeriodsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет событий."])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никаких оговорок."])}
  },
  "eventCancelAtInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Имеет место, если по крайней мере ", _interpolate(_named("minCapacity")), " билетов было продано в общей сложности после ", _interpolate(_named("cancelDuration")), "."])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие успешно создано"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите эту опцию, если хотите создать новое событие. \nМероприятие — это разовая продающая статья, которая проходит в определенное время."])},
  "eventError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать событие"])},
  "eventOrderItemRefundableUntil": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Полностью возвращается до ", _interpolate(_named("until")), " (2 дня до мероприятия). \nПри отмене после этой даты возврат средств не производится."])},
  "eventResultCard": {
    "priceForGroupText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Цена для ", _interpolate(_named("groupSize"))])}
  },
  "eventService": {
    "eventIsCancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие отменено."])},
    "eventSalesEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажа билетов на мероприятие завершена."])}
  },
  "eventUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить событие"])},
  "eventUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие успешно обновлено"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["События"])},
  "eventsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятия успешно созданы"])},
  "eventsCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать события"])},
  "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуга"])},
  "experienceDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите эту опцию, если хотите создать новую услугу."])},
  "experienceSearch": {
    "calendarLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка доступности, это может занять некоторое время."])}
  },
  "experiences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуги"])},
  "farm/apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ферма/квартира"])},
  "feedbackSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отзыв успешно отправлен"])},
  "feedbackSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить отзыв"])},
  "fileUploadFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка файла не удалась!"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
  "fixedTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированный временной интервал"])},
  "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["забыл пароль?"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Из"])},
  "full_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полный адрес"])},
  "gender": {
    "female": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["женский"])},
    "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пол"])},
    "male": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мужской"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другой"])}
  },
  "general_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная Информация"])},
  "general_information_unfilled_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая информация должна быть заполнена и сохранена, прежде чем вы сможете продолжить заполнение формы."])},
  "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать"])},
  "generatedEventsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерированные события"])},
  "goToCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перейти в корзину"])},
  "googleCalendar": {
    "eventModal": {
      "relatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанный ресурс"])},
      "relatedService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанная услуга"])}
    },
    "settings": {
      "authError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка входа в учетную запись Google"])},
      "authenticate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для синхронизации календарей Touringery с Google Календарем, войдите в свою учетную запись Google и согласитесь с условиями."])},
      "modal": {
        "calendarName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
        "calendarType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип календаря"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За сколько времени до события?"])},
        "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пример"])},
        "exampleHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коттедж"])},
        "exampleHousingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["единицы проживания 1,2"])},
        "exampleTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уборка"])},
        "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полное имя"])},
        "googleCalendarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание Google Календаря"])},
        "googleCalendarEventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название события Google Календаря"])},
        "googleCalendarSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки Google Календаря"])},
        "googleCalendarSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название Google Календаря"])},
        "groupSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер группы"])},
        "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
        "moneyjarEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сегодня проданы банки для ресурса \"", _interpolate(_named("name")), "\": ", _interpolate(_named("quantity"))])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
        "productEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Сегодня продано \"", _interpolate(_named("name")), "\": ", _interpolate(_named("quantity"))])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
        "resourceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматический календарь из системы Touringery для ресурса \"", _interpolate(_named("name")), "\""])},
        "resourceEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), ": количество ", _interpolate(_named("quantity"))])},
        "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить настройки календаря"])},
        "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки календаря сохранены!"])},
        "sendReminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получать напоминания перед событиями"])},
        "serviceDescription": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматический календарь из системы Touringery для товара \"", _interpolate(_named("name")), "\""])},
        "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название товара"])},
        "syncActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Синхронизация включена"])},
        "taskEventName": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " (", _interpolate(_named("serviceName")), ", ", _interpolate(_named("unitName")), ")"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить настройки Google Календаря"])},
        "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица проживания"])},
        "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подождите, сохранение может занять некоторое время..."])}
      },
      "table": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
        "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивный"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название"])},
        "reminders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминания"])},
        "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])}
      },
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки Google Календаря"])}
    },
    "type": {
      "EVENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
      "EXPERIENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуга"])},
      "HOUSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилье"])},
      "MONEYJAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копилка"])},
      "PRODUCT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товар"])},
      "RESOURCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурс"])},
      "TASK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача"])}
    }
  },
  "groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя группы"])},
  "groupedServices": {
    "discountPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "groupedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "requiredLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "selectServicesText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "selectServicesToAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["гости"])},
  "guestsCountriesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распределение размещенных по стране проживания"])},
  "guestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О гостях"])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["здесь"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["часы"])},
  "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дом"])},
  "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпус"])},
  "housingCalendar": {
    "changeReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить бронирование"])},
    "goToCartView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр корзины"])},
    "goToReservationView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр бронирования"])},
    "noTimelineEventsInPeriodText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никаких оговорок или закрытий в течение этого периода."])},
    "showInfo": {
      "availableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные единицы измерения"])}
      },
      "disabledUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исключенные единицы"])}
      },
      "reservationsCount": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарезервированные единицы"])}
      },
      "reservedAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Зарезервировано ", "|", " Доступно) единиц"])}
      },
      "selectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывать"])},
      "totalAndAvailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Всего ", "|", " Доступно) единиц"])}
      },
      "totalAndUnavailableUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Всего ", "|", " недоступно) единиц"])}
      },
      "totalUnits": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего единиц"])}
      }
    },
    "unspecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неопределенные"])}
  },
  "housingCalendarDetails": {
    "availableGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступный"])},
    "availableHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступный"])},
    "closedHousingUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыто"])},
    "guestsPerUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гость на единицу"])},
    "housingsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размещение"])},
    "noReservationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Никаких оговорок."])},
    "reservationsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
    "reservedHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сдержанный"])},
    "totalGuestsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
    "totalHousingUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])}
  },
  "housingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите этот вариант, если хотите создать новое жилье"])},
  "housingOrderCard": {
    "adult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["взрослый"])},
    "adults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Взрослые"])},
    "child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ребенок"])},
    "children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дети"])}
  },
  "housingOvernightEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешественники из Эстонии с ночевкой"])},
  "housingOvernightNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешественники из других стран с ночевкой"])},
  "housingRoom": {
    "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удобства в номере"])},
    "seeAllAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все"])}
  },
  "housingSalesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вместимость"])},
  "housingSalesTotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего продаж жилья"])},
  "housingUnit": {
    "displayAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все комнаты"])},
    "doorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код двери"])},
    "housingUnits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилые единицы"])},
    "identificator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте сумму этого типа размещения и уникальный идентификатор"])},
    "infoHelperDoorCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код двери (не обязательно) можно использовать как переменную в шаблонах электронных писем."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилая единица"])}
  },
  "housings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпуса"])},
  "howMany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как много"])},
  "howManyAreYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сколько вас человек?"])},
  "howToGetThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как туда добраться"])},
  "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я БЫ"])},
  "idCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификационный код"])},
  "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор"])},
  "insertEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставить адрес электронной почты"])},
  "invitationsSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашения успешно отправлены"])},
  "invitationsSentError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить приглашения"])},
  "inviteNewUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пригласить новых пользователей"])},
  "inviteUsersForm": {
    "roleAdminInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Админ - может все внутри компании, если компаний несколько, то во всех."])},
    "roleUserInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь - может просматривать и редактировать адресованные ему задачи, календарь (уведомление об изменениях отправляется админу)."])}
  },
  "inviteUsersText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашенные пользователи регистрируются на Touringery и получают приглашение на сайт по электронной почте."])},
  "labels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ярлыки"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык"])},
  "languageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Английский"])},
  "languageEt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эстонский"])},
  "languageFi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["финский"])},
  "languageJa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Японский"])},
  "languageRu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Русский"])},
  "languageServiceGeneralBanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При добавлении информации в поля используйте язык, указанный вами для приложения."])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее обновление"])},
  "lastView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последний просмотр"])},
  "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка..."])},
  "loadingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение"])},
  "locationPublicTransport": {
    "addTransportLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте общественный транспорт"])},
    "distanceKmLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["км"])}
  },
  "logIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "logOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
  "logoExplanationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логотип отображается в боковом меню, форме входа и форме регистрации. \nПри отображении размер логотипа масштабируется в зависимости от места, но максимальная высота составляет 108 пикселей."])},
  "manageAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управлять счетом"])},
  "markAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oтметить как выполненное"])},
  "markAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметить как не выполненное"])},
  "maximum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
  "mealPlanLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["План Питания"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщение"])},
  "minAdvanceTimePastWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальное время бронирования заранее прошло"])},
  "minParticipantsInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Требуется не менее ", _interpolate(_named("minGroupSize")), " проданных билетов до ", _interpolate(_named("cancelTime"))])},
  "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["минуты"])},
  "moneyJarCalendarDetails": {
    "noPaidOrderItemsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этот день ни одной копилки не куплено."])},
    "orderItemsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банки для денег"])},
    "totalUnitsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продано единиц"])}
  },
  "moneyJarDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите этот вариант, если вы хотите создать новую статью о продаже, похожую на услугу, которая позволит вам продавать товары без учета каких-либо ограничений доступности и т. д."])},
  "multimedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мультимедиа"])},
  "multimediaTab": {
    "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить изображения"])},
    "baseFileLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум 5 файлов за раз"])},
    "fetchMultimediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить мультимедийную статью о продаже."])},
    "fileDeleteExistingHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить существующий заголовок"])},
    "fileDeleteFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось удалить файл с именем \"", _interpolate(_named("heading")), "\""])},
    "fileHeadingIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок обязателен."])},
    "fileHeadingTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимальная длина заголовка — 200 символов. \nВ настоящее время это ", _interpolate(_named("length")), " символов."])},
    "fileOverLimit": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Размер файла \"", _interpolate(_named("name")), "\" превышает 5 МБ."])},
    "fileUpdateFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось удалить файл с именем \"", _interpolate(_named("heading")), "\""])},
    "fileUploadFailed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось загрузить файл с названием \"", _interpolate(_named("heading")), "\""])},
    "fileUploadHeaderFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить новый заголовок"])},
    "fileWrongFormat": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Формат файла \"", _interpolate(_named("name")), "\" не поддерживается. \nПоддерживаются: SVG, JPG, JEPG, PNG"])},
    "filesSaveFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить файлы"])},
    "filesSaveFailedPartially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить некоторые файлы"])},
    "filesSaveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файлы успешно сохранены"])},
    "gallery": {
      "altTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Альтернативный тег"])},
      "altTagHelpText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте текст описания, если фото не отображается"])},
      "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
      "headingHelperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте фото в заголовок. \nМаксимальная длина 200 символов."])},
      "maxSize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемый максимальный размер: 5 МБ."])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемые минимальные размеры: 960 x 540 px"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Галерея"])}
    },
    "instructions": {
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инструкции"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте привлекательные и качественные изображения."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note that there are orientations about images sizes and dimensions that should be considered."])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PОбратите внимание, что существуют рекомендации относительно размеров и размеров изображений, которые следует учитывать."])},
      "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Убедитесь, что интеллектуальная собственность принадлежит вам, и вам разрешено загружать мультимедиа в туристическую информационную систему. При добавлении мультимедиа вы предоставляете администратору бесплатную всемирную простую лицензию на использование добавленной вами информации. Подробнее об условиях использования."])}
    },
    "isServiceHeaderLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заглавное фото"])},
    "ticket": {
      "addImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить изображение"])},
      "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете добавить изображение в билет вашего товара."])},
      "minDimensions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендуемые минимальные размеры: широкая сторона на 150 пикселей."])},
      "noPricesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет цен для отображения"])},
      "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр билета"])},
      "showPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр дисплея"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билет"])}
    }
  },
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
  "nearbyTransportStops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ближайшие остановки общественного транспорта"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое событие"])},
  "newResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый ресурс"])},
  "newService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новая статья о продаже"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Следующий"])},
  "night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ночь"])},
  "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ночи"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет"])},
  "noAvaiableDatesInMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В этом месяце нет доступных дат"])},
  "noAvailabilityRecommendation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно мест на эти даты. \nПосмотреть другие предложения от этого провайдера"])},
  "noAvailabilityWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно мест на эти даты. \nПожалуйста, попробуйте другие даты"])},
  "noEventAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У вас нет периодов событий. Пожалуйста, проверьте фильтры или создайте хотя бы один период."])},
  "noExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет существующих клиентов"])},
  "noOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет вариантов"])},
  "noPermissionsForRouteAccessError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Доступ запрещен! \nУ вас нет прав на просмотр страницы \"", _interpolate(_named("route")), "\"."])},
  "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет результатов для отображения"])},
  "noRightsErrorMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ запрещен. \nУ вас нет прав на выполнение этого действия"])},
  "noSearchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет результатов по этому запросу"])},
  "noTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет задач"])},
  "notAccepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не принимаются"])},
  "notDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не выполнено"])},
  "notReadyYet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот раздел находится в разработке"])},
  "numberOfRooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество комнат"])},
  "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Непрерывный"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть"])},
  "openDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть подробные сведения"])},
  "openPartnershipDetailLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть представление партнера"])},
  "openTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открытый временной интервал"])},
  "optionSelectedLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("length")), " выбраны варианты"])},
  "order": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["порядок"])}
  },
  "orderClientPage": {
    "addressDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес нужно указать, если вы хотите получить счет"])},
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я прочитал и согласен с условиями использования"])},
    "confirmClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при подтверждении информации о клиенте"])},
    "confirmClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о клиенте успешно подтверждена"])},
    "contactInfoDiscaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы используем ваши данные только для связи с вами в случае необходимости"])},
    "tourTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общие условия"])},
    "updateClientError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Произошла ошибка при обновлении клиента заказа"])},
    "updateClientSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиент заказа успешно обновлен"])}
  },
  "orderConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение заказа"])},
  "orderSummary": {
    "cancellationHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена заказа"])},
    "refundableLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возврат"])},
    "refundedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возмещено"])}
  },
  "order_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер счёта"])},
  "origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Источник"])},
  "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
  "overviewCalendar": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
    "calendarSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбор календаря"])},
    "calendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kалендари"])},
    "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["День"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
    "more": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("eventsNr")), " еще"])},
    "multidayEventsInTimeline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать события, охватывающие несколько дней, на временной шкале"])},
    "noCalendars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет календарей"])},
    "noOrdersInPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказов в этот период нет"])},
    "orderCards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор заказов"])},
    "orderNo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Заказ №", _interpolate(_named("referenceNumber"))])},
    "orderType": {
      "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
      "CONFIRMED_OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ"])},
      "CONFIRMED_ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждённый Заказ"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
      "LOCKED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заблокировано"])},
      "OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предложение"])}
    },
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказы"])},
    "otherDays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В Другие Дни"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сбросить"])},
    "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать все"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cегодня"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])}
  },
  "overviewTabs": {
    "bookingCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
    "bookingList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список бронирований"])},
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])}
  },
  "participants": {
    "maxGroupSizeText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Выбрано ", _interpolate(_named("groupSize")), "/", _interpolate(_named("maxGroupSize"))])},
    "participantsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участники"])}
  },
  "partnership": {
    "acceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять партнерство"])},
    "acceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если обе стороны согласились на партнерство, ваши рекламные статьи будут отображаться на страницах друг друга. \nФункция распределения доходов также будет снова включена."])},
    "backToPartnersList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все партнерства"])},
    "companyHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Первый партнер"])},
    "confirmAcceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердите принятие"])},
    "confirmUnacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить деактивацию"])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности партнерства"])},
    "partnerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Второй партнер"])},
    "payoutPercentageExampleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Например, во время активного партнерства ваши статьи о продажах будут отображаться в нижней части страниц статей о продажах вашего партнера, а также их статьи и статьи других партнеров. \nПартнер получит свою долю, когда на своей странице клиент нажмет на вашу продающую статью и приступит к ее заказу и оплате."])},
    "payoutPercentageInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент выплат, которым компания готова поделиться со своим партнером. \nСовместное использование происходит только в том случае, если продажа достигается непосредственно через статьи продаж партнера."])},
    "payoutPercentageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доля выплат партнера"])},
    "payoutPercentageModalText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнер будет уведомлен, когда ваша доля выплат изменится."])},
    "statusLabel": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активный"])},
      "INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неактивный"])}
    },
    "tabs": {
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашает"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнеры"])}
    },
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнеры"])},
    "unacceptPartnershipButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивировать партнерство"])},
    "unacceptPartnershipInfoText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Деактивация партнерства означает, что ваши статьи о продажах не будут отображаться на страницах друг друга. \nФункция распределения доходов также будет отключена для обеих сторон. \nБудущие выплаты от более раннего распределения доходов не будут затронуты."])},
    "updatePartnershipAcceptanceErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить партнерство."])},
    "updatePartnershipAcceptanceSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнерство успешно обновлено."])},
    "updatePartnershipPayoutPercentageErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить долю выплат партнера."])},
    "updatePartnershipPayoutPercentageSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доля выплат партнера успешно обновлена."])},
    "updatePayoutPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить долю выплат"])}
  },
  "partnershipInvite": {
    "acceptInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принять приглашение"])},
    "acceptInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось принять приглашение партнера."])},
    "acceptInviteRegisterFirst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если у вас еще нет учетной записи поставщика услуг в Touringery, вам необходимо будет пройти процедуру регистрации, прежде чем повторять попытку приглашения."])},
    "acceptInviteRequiresLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы принять приглашение, вам необходимо сначала войти в свою учетную запись поставщика услуг."])},
    "acceptInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение партнера успешно принято. \nВы будете перенаправлены на просмотр сведений о партнере."])},
    "acceptInviteVisitorModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуется вход"])},
    "companyWantsYouToBecomeTheirPartner": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("company")), " приглашает вас стать их партнером в Touringery."])},
    "createInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить приглашение партнера."])},
    "createInviteModalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый партнер"])},
    "createInviteSubmitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить приглашение"])},
    "createInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение партнера успешно отправлено."])},
    "detailsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение к партнерству"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашать"])},
    "inviteFormInfoHelperFirstParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение партнера будет отправлено на указанный адрес электронной почты. \nЭлектронное письмо будет содержать ссылку, которая приведет получателя к просмотру деталей партнерского приглашения. \nТам они могут либо принять, либо отклонить приглашение."])},
    "inviteFormInfoHelperSecondParagraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы принять приглашение, посетитель должен войти в систему как пользователь другого поставщика услуг. \nОтказ может сделать любой посетитель, имеющий доступ к ссылке."])},
    "inviteInFinalStatusText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На приглашение уже получен ответ."])},
    "reinviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить повторное приглашение."])},
    "reinviteLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить приглашение еще раз"])},
    "reinviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение успешно отправлено еще раз."])},
    "rejectInvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонить приглашение"])},
    "rejectInviteErrorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отклонить приглашение."])},
    "rejectInviteSuccessMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение отклонено."])},
    "statusLabel": {
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принял"])},
      "DECLINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклоненный"])},
      "EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истекший"])},
      "OPENED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыто"])},
      "SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправил"])}
    },
    "whatHappensWhenYouAccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнерство позволяет вам показывать другие предложения вокруг вас. \nВы и ваш партнер также будете требовать небольшой процент от продажи, если он будет достигнут непосредственно по вашим ссылкам."])}
  },
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "passwordAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить Пароль"])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль изменен!"])},
  "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплата"])},
  "paymentBeneficiaryName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя Получателя"])},
  "paymentDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали платежа"])},
  "paymentInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о платеже"])},
  "paymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Метод оплаты"])},
  "paymentOption": {
    "bankLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банковский платеж"])},
    "creditCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредитная карта"])}
  },
  "payoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата"])},
  "payoutInfo": {
    "completedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата произведена."])},
    "initiatedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начат процесс выплаты."])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты не будет. \nЭто происходит, когда отмена происходит с полным возвратом средств."])},
    "notReadyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус выплаты по умолчанию. \nОн изменится после завершения или отмены бронирования."])}
  },
  "payoutStatus": {
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаченый"])},
    "initiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инициировано"])},
    "noPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет выплаты"])},
    "notReady": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На удерживании"])}
  },
  "payoutStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусы выплат"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["люди"])},
  "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Процент"])},
  "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
  "periodAvailability": {
    "availabilityHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступность"])},
    "availabilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите день и время, в течение которого ресурс доступен"])}
  },
  "periodUnit": {
    "monthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Месяц"])},
    "selectMonthLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите месяц"])},
    "selectPeriodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите период"])},
    "selectUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период"])},
    "weekLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неделя"])}
  },
  "permissions": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
    "clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клиенты"])},
    "helper": {
      "addCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно добавить новую компанию"])},
      "readCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно просмотреть информацию календаря"])},
      "readClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно увидеть информацию о клиенте"])},
      "readCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно увидеть менеджер каналов и содержимое настроек."])},
      "readReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может видеть содержание бухгалтерского учета и отчетов"])},
      "readResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно видеть ресурсы"])},
      "readServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно увидеть статьи о продажах и информацию о дополнительных услугах."])},
      "readTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может видеть информацию о задачах"])},
      "readTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно увидеть информацию о билетах"])},
      "updateClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно редактировать информацию о клиенте"])},
      "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может редактировать контракты компании, платежные данные, настройки менеджера каналов и партнерства."])},
      "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно редактировать общую информацию о компании и информацию о местоположении компании."])},
      "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может редактировать роли пользователей компании и устанавливать права."])},
      "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно редактировать шаблоны писем компании и вопросы формы регистрации."])},
      "updateReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
      "updateResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно редактировать ресурсы"])},
      "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно добавить новый товар и новую дополнительную услугу. \nМожет редактировать статьи о продажах, а также общий контент и мультимедиа дополнительных услуг."])},
      "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Может публиковать и архивировать рекламные статьи и дополнительные услуги."])},
      "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно редактировать ресурсы статьи о продажах, часы работы, цены, информацию о жилье из пункта меню статьи о продажах, а также из календаря."])},
      "updateTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Можно редактировать задачи"])},
      "updateTickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права на валидацию билетовõ"])}
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты"])},
    "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы"])},
    "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продающие статьи"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билеты"])},
    "updateCompanyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежи компании, контракты"])},
    "updateCompanySettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки компании"])},
    "updateCompanyUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи компании"])},
    "updateCompanyWorkflowHelpers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощники по рабочему процессу компании"])},
    "updateServiceContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержание продающих статей"])},
    "updateServiceIsPublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публикация продающих статей"])},
    "updateServicePolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика продаж товаров"])}
  },
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
  "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["фото"])},
  "pleaseAgreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, отметьте, что вы согласны с условиями, чтобы продолжить"])},
  "pleaseSelectBank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите кредитную карту или банк"])},
  "pleaseSelectPaymentMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите способ оплаты"])},
  "plurals": {
    "adult": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " взрослых"]), _normalize([_interpolate(_named("count")), " взрослый"]), _normalize([_interpolate(_named("count")), " взрослых"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " детей"]), _normalize([_interpolate(_named("count")), " ребенок"]), _normalize([_interpolate(_named("count")), " детей"])])},
    "elderly": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " пожилых"]), _normalize([_interpolate(_named("count")), " пожилой"]), _normalize([_interpolate(_named("count")), " пожилых"])])},
    "numberOfAdditionalServices": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " дополнительных услуг"]), _normalize([_interpolate(_named("count")), " дополнительная услуга"]), _normalize([_interpolate(_named("count")), " дополнительных услуги"])])},
    "numberOfGuests": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " гостей"]), _normalize([_interpolate(_named("count")), " гость"]), _normalize([_interpolate(_named("count")), " гостей"])])},
    "numberOfNights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " ночей"]), _normalize([_interpolate(_named("count")), " ночь"]), _normalize([_interpolate(_named("count")), " ночи"])])},
    "numberOfParticipants": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " участников"]), _normalize([_interpolate(_named("count")), " участник"]), _normalize([_interpolate(_named("count")), " участника"])])},
    "numberOfRooms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " комнат"]), _normalize([_interpolate(_named("count")), " комната"]), _normalize([_interpolate(_named("count")), " комнаты"])])}
  },
  "politicallyExposed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политически значимый человек"])},
  "preferences": {
    "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дни"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расстояние (км)"])},
    "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["гости"])},
    "includedInPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включено в цену"])},
    "labels": {
      "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступность"])},
      "amenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удобства"])},
      "checkInHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время заезда"])},
      "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])},
      "difficulty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сложность"])},
      "furtherInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дальнейшая информация"])},
      "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Языки"])},
      "numOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество гостей"])},
      "stayDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность пребывания"])},
      "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель"])}
    },
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
    "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])}
  },
  "preferredLang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Язык общения"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назад"])},
  "previousMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты предыдущего месяца"])},
  "price": {
    "additionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные услуги"])},
    "additionalServicesVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительные услуги НДС (20%)"])},
    "createTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать тикет"])},
    "descriptionPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание цены (необязательно)"])},
    "fullPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полная цена"])},
    "namePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название цены"])},
    "priceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
    "servicePrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена статьи продажи"])},
    "serviceVAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товарная статья НДС (20%)"])}
  },
  "priceDynamicRows": {
    "addRowLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить изменение цены"])},
    "durationFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влияет ли продолжительность на цену?"])},
    "durationInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы указали, что продолжительность влияет на цену. \nЭто означает, что вы можете указать изменения цены для каждой единицы времени продолжительности, которые изменяют их окончательную цену."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения цены продолжительности состоят из следующих полей"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание изменения цены продолжительности"])},
      "isImportantHourlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При почасовом типе ценообразования продолжительность всегда важна и не может быть отключена."])},
      "isImportantNightlyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для ночного типа ценообразования продолжительность всегда важна и не может быть отключена."])},
      "isImportantPackagedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для пакетного типа ценообразования продолжительность не имеет значения и не может быть включена."])},
      "isPriorityHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если флажок установлен, это изменение цены также будет применяться ко всем предыдущим часам, переопределяя любые другие изменения цены, которые могли быть применены к ним."])},
      "isPriorityNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если этот флажок установлен, это изменение цены также будет применено ко всем предыдущим ночам, перекрывая любые другие изменения цен, которые могли быть применены к ним."])},
      "lowerBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижний предел часов для заданных границ изменения цены. \nНижний предел включен, к нему будут применяться изменения цены."])},
      "lowerBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижний предел ночей для заданных границ изменения цены. \nНижний предел включен, к нему будут применяться изменения цены."])},
      "rowsHourlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию каждый час бронирования будет иметь свою цену, установленную ранее указанной базовой ценой. \nЗдесь вы можете изменить цену за каждый час, добавив правила изменения цены. \nБольше информации в значке информации рядом с заголовком."])},
      "rowsNightlyDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию для каждой ночи бронирования будет установлена ​​ранее указанная базовая цена. \nЗдесь вы можете изменить цену за каждую ночь, добавив правила изменения цены. \nБольше информации в значке информации рядом с заголовком."])},
      "rowsPackagedDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность не влияет на цену пакетного типа. \nЦена будет указана за все бронирование, а не за каждый час/ночь."])},
      "upperBoundHourInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верхний предел часов для заданных границ изменения цены. \nВерхний предел является эксклюзивным, изменения цены на него не распространяются.\n\nВерхний предел не требуется, но без ограничений может быть только одно правило длительности."])},
      "upperBoundNightInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верхний предел ночей для заданных границ изменения цены. \nВерхний предел является эксклюзивным, изменения цены на него не распространяются.\n\nВерхний предел не требуется, но без ограничений может быть только одно правило длительности."])}
    },
    "durationRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение цены в зависимости от продолжительности"])},
    "groupSizeFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влияет ли размер группы на цену?"])},
    "groupSizeInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы указали, что размер группы влияет на цену. \nЭто означает, что вы должны указать хотя бы одно изменение цены."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения цены размера группы состоят из следующих полей"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание изменения цен на размер группы"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если этот флажок установлен, это изменение цены также будет применено ко всем предыдущим членам группы, переопределяя любые другие изменения цен, которые могли быть применены к ним."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нижний предел количества человек для заданных границ изменения цены. \nНижний предел включен, к нему будут применяться изменения цены."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["По умолчанию, когда размер группы важен, размер группы будет использоваться в качестве множителя для получения окончательной цены. \nЗдесь вы можете изменить, как каждый дополнительный человек повлияет на цену, определив изменения цены. \nБольше информации в значке информации рядом с заголовком."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наибольшее количество людей, разрешенных для применения правила."])}
    },
    "groupSizeRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение цен в зависимости от размера группы"])},
    "hourUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часы"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В час"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])}
    },
    "lowerBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
    "nightUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ночи"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За ночь"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ночь"])}
    },
    "noOverlapRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия изменения цены не могут пересекаться"])},
    "onlyOneLimitlessRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Только одно условие изменения цены может иметь неустановленную (безграничную) верхнюю границу"])},
    "personUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лица"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На человека"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек"])}
    },
    "priceChangeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Значение, которое добавляется к окончательной цене, если бронирование соответствует условию правила."])},
    "priceChangeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение цены"])},
    "priorityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить к предыдущему"])},
    "quantityUnit": {
      "multipleLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
      "perUnitLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За количество"])},
      "singularLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])}
    },
    "relatedResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Требуемое количество связанного ресурса зависит от изменения цены."])},
    "relatedResourceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанный ресурс"])},
    "relatedResourcePlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбирать"])},
    "resourceConditionsPerRelatedResourceRestrictionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для связанных изменений цен на ресурсы ограничение перекрытия может произойти только между изменениями цен, которые связаны с одним и тем же связанным ресурсом."])},
    "resourcesFlagLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влияют ли сопутствующие ресурсы на цену?"])},
    "resourcesInfo": {
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы указали, что связанные ресурсы влияют на ценообразование. \nЭто означает, что вы должны указать хотя бы одно изменение цены."])},
      "fieldIntroText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения цены ресурса состоят из следующих полей"])},
      "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание изменения цен на сопутствующие ресурсы"])},
      "isPriorityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если этот флажок установлен, это изменение цены также будет применяться ко всем ранее связанным количествам/часам ресурсов, переопределяя любые другие изменения цен, которые могли быть применены к ним."])},
      "lowerBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименьшее количество ресурсов, необходимое для применения правила длительности."])},
      "rowsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда ресурсы важны, вы можете указать, как каждый добавленный ресурс влияет на окончательную цену.\n\nДля ресурсов, которые представляют людей, цена будет меняться в зависимости от количества часов.\n\nДля ресурсов, представляющих объекты, цена будет меняться в зависимости от количества ресурсов, необходимых для резервирования. \nБольше информации в значке информации рядом с заголовком."])},
      "upperBoundInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верхний предел количества/часов ресурса для заданных границ изменения цены. \nВерхний предел является эксклюзивным, изменения цены на него не распространяются.\n\nВерхний предел не требуется, но без ограничений может быть только одно правило длительности."])}
    },
    "resourcesRowsHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение базовой цены ресурсов"])},
    "restrictionsSubheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])},
    "upperBoundLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])}
  },
  "pricingProperties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цены"])},
  "pricingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип ценообразования"])},
  "pricingTypes": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие"])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ежечасно"])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денежная банка"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ночной"])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В упаковке"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])}
  },
  "pricingTypesInfo": {
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоимость билетов (взрослый, пожилой, детский) указана за все мероприятие."])},
    "hourly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенная цена указана за каждый час и будет умножена на продолжительность бронирования.\n\nВ настоящее время вы можете указать минимальную и максимальную продолжительность.\n\nВАЖНЫЙ:\n\nВ настоящее время время начала не может быть ограничено, клиент может выбрать любой, который соответствует рабочему времени и не зарезервирован.\n\nЦена не зависит от размера группы. \nБронирование на 2 часа будет стоить одинаково для одного человека и группы из 10 человек.\n\nВ настоящее время поддерживается только одно одновременное резервирование."])},
    "moneyJar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончательная цена для заказчика. \nПоставщик услуг определяет товар"])},
    "nightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена будет рассчитана на основе количества ночей в бронировании.\n\nВАЖНЫЙ:\n\nВ настоящее время цена за все бронирование основана на цене первой ночи."])},
    "packaged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенная цена действует на весь срок бронирования, который вы можете указать при выборе этого типа ценообразования.\n\nВАЖНЫЙ:\n\nВ настоящее время время начала не может быть ограничено, это зависит от клиента.\n\nЦена не зависит от размера группы. \nБронирование на 2 часа будет стоить одинаково для одного человека и группы из 10 человек.\n\nВ настоящее время поддерживается только одно одновременное резервирование."])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена будет рассчитана на основе количества"])}
  },
  "priority": {
    "critical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критический"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокая"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Низкий"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середина"])},
    "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срочность"])}
  },
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Политика конфиденциальности"])},
  "productCalendarDetails": {
    "noSalesForDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет купленных товаров на эту дату"])}
  },
  "profileSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки профиля"])},
  "publicNoServiceBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похоже, вы направляетесь не в ту сторону."])},
  "publicNoServiceTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О, нет!"])},
  "publicService": {
    "archivedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статья о продаже недоступна"])}
  },
  "publicTransport": {
    "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аэропорт"])},
    "bus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автобус"])},
    "harbor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гавань"])},
    "train": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тренироваться"])},
    "tram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Трамвай"])},
    "trolley": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Троллейбус"])}
  },
  "purchasedAtDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата покупки"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
  "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оценивать"])},
  "readLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать меньше"])},
  "readMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Читать далее"])},
  "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина"])},
  "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
  "recipientWithPlural": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель/получатели"])},
  "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатели"])},
  "recommendedCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация в"])},
  "recommendedCheckOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выезд до"])},
  "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мусор"])},
  "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказался"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистр"])},
  "registration": {
    "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск компании"])}
  },
  "registrationCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна регистрации"])},
  "registrationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать учетную запись"])},
  "registrationSuccessEmailSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация прошла успешно! \nНа ваш почтовый ящик отправлено письмо с активацией"])},
  "registryNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрационный код"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклоненный"])},
  "relate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Относятся"])},
  "relatedAvailableServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно в выбранные даты"])}
  },
  "relatedService": {
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид"])}
  },
  "relatedServices": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите дополнительно"])},
    "partnersHeading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получите дополнительно от партнеров"])}
  },
  "relatedUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные пользователи"])},
  "related_check_in_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные вопросы о регистрации"])},
  "related_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные ресурсы"])},
  "related_service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные статьи о продажах"])},
  "related_templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные шаблоны писем"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалять"])},
  "report": {
    "deposit": {
      "header": {
        "commissionsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за услугу"])},
        "depositsBeforePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс в начале периода"])},
        "depositsDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученный депозит"])},
        "depositsEndOfPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс в конце периода"])},
        "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата депозите"])},
        "partnersPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты партнерам"])},
        "payoutAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата платежа"])},
        "refundedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата возврата"])},
        "refundedDuringPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возвраты"])},
        "serviceProviderPayouts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты поставщику услуг"])}
      }
    },
    "errorFetchData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать отчет"])},
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать"])},
    "generateCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать CSV"])},
    "generatePdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сгенерировать pdf"])},
    "partners": {
      "header": {
        "payoutAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплаты"])},
        "payoutTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель выплат"])}
      }
    },
    "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты"])},
    "sales": {
      "header": {
        "clientsCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна клиента"])},
        "clientsName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя клиента"])},
        "commissionFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за услугу"])},
        "net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нетто"])},
        "partnerPayout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доля партнера"])},
        "payoutDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата оплаты"])},
        "priceLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
        "refundFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плата за возврат"])},
        "refundedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата возврата"])},
        "salesArticle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуга"])},
        "salesReportGenerated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создан отчет о продажах"])},
        "sumWithVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма с НСО"])},
        "sumWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма без НСО"])},
        "totalVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суммарный НСО"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
        "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НСО(%)"])}
      }
    },
    "table": {
      "additionalService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доп. услуги"])},
      "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие"])},
      "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опыт"])},
      "housing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проживание"])},
      "moneyjar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копилка"])}
    },
    "tabs": {
      "bookKeeping": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бухгалтерский учет"])},
      "depositReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозитный отчет"])},
      "partnersReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет партнеров"])},
      "salesReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчет по продажам"])},
      "statisticsReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статистический отчет"])}
    },
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
    "touringeryCommission": {
      "header": {
        "payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть"])},
        "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тотальная распродажа"])}
      }
    }
  },
  "reports": {
    "statistics": {
      "accomodatedEstonianNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жители Эстонии - количество проведенных ночей"])},
      "accomodatedEstonianPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жители Эстонии - количество размещенных туристов"])},
      "accomodatedForeignNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иностранные гости - количество проведенных ночей"])},
      "accomodatedForeignPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иностранные гости - количество размещенных туристов"])},
      "accomodatedNightsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество проведенных ночей"])},
      "accomodatedPeopleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество размещенных туристов"])},
      "bedPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спальные места"])},
      "campingPlacesLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество мест на площадках для кемпинга и в трейлерных парках"])},
      "countryCodeHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код страны"])},
      "openDaysLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество дней открытия"])},
      "roomNightsSoldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Использование жилых помещений (количество проданных номеро-суток)"])},
      "roomsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество единиц жилья"])},
      "tripPurpose": {
        "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Командировка"])},
        "conference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["..участие в конференции"])},
        "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прочее (например, ночи, проведенные в рамках транзитного туризма, медицинского туризма и т. д.)"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее количество размещенных туристов и проведенных ночей"])},
        "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праздничная поездка"])}
      }
    }
  },
  "requestLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить ссылку"])},
  "requestNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить новый пароль"])},
  "requestSentLinkSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос отправлен! \nМы отправили ссылку для сброса пароля."])},
  "requests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы"])},
  "requiredSettingsAlert": {
    "beneficialOwnersNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бенефициарные владельцы/владельцы"])},
    "companyContractNotAgreedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Согласен с условиями договора"])},
    "companyNoServiceContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор купли-продажи между поставщиком услуг и клиентом"])},
    "dynamicShowAllLabel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показать больше (", _interpolate(_named("count")), ")"])},
    "hideMoreSettingsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть больше"])},
    "paymentInfoNotFilledText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежные реквизиты для выплат"])}
  },
  "requirementsFilledError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возникла ошибка. \nПожалуйста, проверьте, все ли заполнено правильно"])},
  "resendConfirmationEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить подтверждение заказа"])},
  "resendConfirmationEmailAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете повторно отправить подтверждение заказа клиенту."])},
  "resendConfirmationEmailAlertWithLastDate": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Здесь вы можете повторно отправить подтверждение заказа клиенту. Предыдущий был отправлен: ", _interpolate(_named("emailConfirmationAt")), "."])},
  "reservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бронирование"])},
  "reservationData": {
    "cannotChangePeriodForExternalSourcesAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно изменить период, поскольку резервирование поступает из внешнего источника."])},
    "originalGuestsAlert": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изменилось количество гостей, изначально было: ", _interpolate(_named("guests")), "."])}
  },
  "reservationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата бронирования"])},
  "reservationEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Окончание бронирования"])},
  "reservationHousingUnit": {
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при сохранении жилой единицы"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилая единица успешно сохранена"])}
  },
  "reservationLength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность бронирования"])},
  "reservationSalesEndTimeLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания онлайн-продаж"])},
  "reservationStart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало бронирования"])},
  "reservationStatus": {
    "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменено"])},
    "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершенный"])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Истекший"])},
    "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплаченный"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ожидании"])}
  },
  "reservationStatuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статусы бронирования"])},
  "reservationType": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип отношения"])},
    "oneCapacityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одна вместимость на человека"])},
    "oneQuantityPerEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одно количество на мероприятие"])},
    "oneQuantityPerPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одно количество на человека"])},
    "oneQuantityPerReservation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одно количество/час на бронирование"])}
  },
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурс"])},
  "resourceCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурс успешно создан"])},
  "resourceCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать ресурс"])},
  "resourceDefaultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль/Заголовок"])},
  "resourceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурс успешно удален"])},
  "resourceGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа"])},
  "resourceGroupCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать группу"])},
  "resourceGroupCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа успешно создана"])},
  "resourceGroupCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать группу"])},
  "resourceGroupUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновление группы"])},
  "resourceGroupUpdateError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось обновить группу"])},
  "resourceGroupUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группа успешно обновлена"])},
  "resourceGroups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
  "resourceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя ресурса"])},
  "resourceObjectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
  "resourcePeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите дату, в течение которой ресурс доступен"])},
  "resourceSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурс успешно сохранен"])},
  "resourceSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить ресурс"])},
  "resourceTabs": {
    "resourceCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
    "resourceGroupList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Группы"])},
    "resourceList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы"])},
    "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])}
  },
  "resourceUserTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ресурсы"])},
  "respondedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответил"])},
  "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль"])},
  "room": {
    "alertinfotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жилая единица – это жилье для гостя/группы гостей. \nПожалуйста, добавляйте каждую единицу жилья отдельно; \nили добавьте количество ваших (похожих) единиц жилья"])},
    "maxGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальное количество гостей"])},
    "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комната"])},
    "roomInfoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вставьте количество комнат в этом жилье, чтобы указать информацию о каждой комнате"])},
    "roomType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип номера"])},
    "rooms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номера"])},
    "unitCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория"])}
  },
  "roomAmenities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удобства в номере"])},
  "roomLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная информация"])},
  "roomsSoldEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешественники из Эстонии"])},
  "roomsSoldNonEe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешественники из других стран"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Строк на странице"])},
  "rrule": {
    "end:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец:"])},
    "endDate:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания:"])},
    "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о событии"])},
    "every": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый"])},
    "everyDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". \nдата каждый"])},
    "everyDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый день"])},
    "everyMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый месяц"])},
    "everyOtherDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["день"])},
    "everyOtherMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
    "everyOtherWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неделя"])},
    "everyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["раз"])},
    "everyWeek": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждую неделю"])},
    "everyWorkDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каждый рабочий день"])},
    "first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["первый"])},
    "fourth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["четвертый"])},
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пятница"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["понедельник"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mесяц"])},
    "recurresEvery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторяется каждые"])},
    "repetitionFrequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частота повторения"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["суббота"])},
    "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["второй"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["воскресенье"])},
    "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["третий"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["четверг"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вторник"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["среда"])}
  },
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранять"])},
  "saveAndContinue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить и продолжить"])},
  "saveNewCoordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить координаты карты"])},
  "scanner": {
    "anonymousCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анонимная камера"])},
    "cameraAccessSecureContextOnlyError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ к камере поддерживается только в безопасном контексте, например https или localhost."])},
    "cameraBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканирование с помощью камеры"])},
    "cameraStreamingNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потоковая передача с камеры не поддерживается браузером."])},
    "chooseAnother": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать другого"])},
    "chooseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите изображение"])},
    "chooseImageNoImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите изображение – изображение не выбрано"])},
    "codeScanner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканер кода"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])},
    "fileBasedScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканирование на основе файлов"])},
    "getUserMediaError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка получения userMedia, ошибка ="])},
    "idle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Праздный"])},
    "launchingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запускаем камеру..."])},
    "loadingImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузка изображения..."])},
    "navigatorMediaDevicesNotSupportedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Устройство не поддерживает navigator.mediaDevices, в данном случае поддерживается только cameraIdOrConfig — параметр deviceId (строка)."])},
    "noCameraFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камера не найдена"])},
    "noCameras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет камер"])},
    "noImageChosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изображение не выбрано"])},
    "notAllowedPermissionDeniedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NotAllowedError: Разрешение отклонено"])},
    "orDropImageToScan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или перетащите изображение для сканирования"])},
    "orDropImageToScanOtherFilesNotSupported": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Или перетащите изображение для сканирования (другие файлы не поддерживаются)."])},
    "permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешение"])},
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Питаться от"])},
    "qrCodeParseError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка разбора QR-кода, ошибка ="])},
    "querySupportedDevicesError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невозможно запросить поддерживаемые устройства, неизвестная ошибка."])},
    "reportIssues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сообщить о проблемах"])},
    "requestCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить разрешения камеры"])},
    "requestingCameraPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросить разрешения камеры..."])},
    "scanAnImageFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканирование файла изображения"])},
    "scanUsingCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканирование с помощью камеры напрямую"])},
    "scannerPaused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканер приостановлен"])},
    "scanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканирование"])},
    "selectCamera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите камеру"])},
    "startScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать сканирование"])},
    "stopScanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Остановить сканирование"])},
    "switchOffTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выключить фонарь"])},
    "switchOffTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось выключить фонарик"])},
    "switchOnTorch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включить фонарик"])},
    "switchOnTorchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось включить фонарик"])},
    "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["масштабировать"])}
  },
  "scheduledEmails": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланированные электронные письма"])},
    "noData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет писем"])},
    "scheduledAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запланировано"])},
    "sendEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить письмо"])},
    "sendEmailError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправка электронной почты не удалась"])},
    "sendEmailSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта успешно отправлена"])},
    "sentAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последнее отправлено в"])},
    "template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон"])}
  },
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
  "searchCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория поиска"])},
  "searchExistingClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск среди существующих клиентов"])},
  "searchForAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск адреса"])},
  "searchToSeeResultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите даты и информацию о посетителях, чтобы увидеть наличие свободных мест"])},
  "seeAllClients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть всех клиентов"])},
  "seeAllResources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть все ресурсы"])},
  "seeAllServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все статьи о продажах"])},
  "seeAllTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посмотреть все задачи"])},
  "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Узнать больше"])},
  "selectAdditionalServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать дополнительные услуги"])},
  "selectAdditionalServicesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дополнительные услуги ниже, которые вы хотите связать"])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите компанию"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страну"])},
  "selectDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату"])},
  "selectRelatedResource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите связанный ресурс"])},
  "selectResourcesToRelate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите ресурсы ниже, которые вы хотите связать"])},
  "sendInvitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить приглашения"])},
  "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])},
  "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажная статья"])},
  "serviceAdditionalService": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете связать дополнительные продукты, ранее введенные из меню товаров для продажи. \nВыбранные дополнительные товары отображаются в корзине перед оплатой."])}
  },
  "serviceAdvancedTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Передовой"])},
  "serviceArchiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось заархивировать статью о продаже"])},
  "serviceArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статья о продаже успешно заархивирована"])},
  "serviceCheckInQuestions": {
    "addQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить вопрос"])},
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите вопросы, которые нужно добавить в форму регистрации коммерческой статьи или удалить из нее."])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменения будут применяться ко всем связанным статьям продаж."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные вопросы о регистрации"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все вопросы см. в разделе «Настройки» — «Вопросы для проверки». \nЗдесь вы можете добавить вопросы к текущему пункту услуги."])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить отношения"])},
    "selectQuestions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать связанные вопросы"])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма регистрации всех продающих статей. Дополнительные вопросы находятся в Настройках -> Форма регистрации."])}
  },
  "serviceContact": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контактная информация"])},
    "headerDefaultsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поля без значений по умолчанию соответствуют настройкам профиля компании, как показано в заполнителях."])},
    "headerGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите контактную информацию для клиентов по конкретной статье продаж."])},
    "nameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя контактного лица. \nПоле скрыто от клиентов, если оно оставлено пустым."])},
    "overrideNotificationsGeneralInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если этот флажок установлен, указанный адрес электронной почты будет использоваться вместо электронной почты компании для отправки уведомлений по электронной почте в отношении этой услуги."])},
    "overrideNotificationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Используйте для уведомлений по электронной почте"])},
    "overrideNotificationsSettingsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений об отправке по электронной почте остаются в силе."])}
  },
  "serviceDuplicated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статья о продаже успешно продублирована"])},
  "serviceDuplicationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дублирование статьи о продаже не удалось"])},
  "serviceEvent": {
    "availableCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступная мощность"])},
    "cancelAtHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время отмены"])},
    "cancellationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете установить дату и время, к которому должно быть набрано минимальное количество участников/минимальное количество проданных билетов. При несоблюдении минимального количества участников мероприятие автоматически отменяется. Деньги, полученные за купленные билеты, будут возвращены, участники будут уведомлены автоматически. Стоимость трансферов, связанных с отменой мероприятия, должна быть возмещена организатором."])},
    "capacityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если для вашего мероприятия требуется минимальное количество участников, введите его здесь. Если мероприятие все равно состоится, то минимум указывать не нужно, достаточно ввести максимальное количество участников."])},
    "eventCapacityHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вместимость"])},
    "eventPeriodHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время события"])},
    "maxCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимум"])},
    "minCapacityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимум"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Положение дел"])},
    "timeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дату начала события из календаря, нажмите на значок часов и выберите время начала."])},
    "updateEventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить событие"])}
  },
  "serviceEventCalendar": {
    "onlyEventsFilterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать только с событием"])}
  },
  "serviceEventsArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятия успешно заархивированы"])},
  "serviceEventsArchivedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось заархивировать события"])},
  "serviceFilter": {
    "privateState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видимость"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])}
  },
  "serviceGeneral": {
    "categoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбирайте ближайший из возможных."])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При необходимости растяните окно от нижнего правого угла к большему."])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ярлыки предназначены для внутреннего использования поставщиком услуг и позволяет группировать различные услуги missingпо потребностям и фильтровать их на основе этого в виде списка"])},
    "searchCategoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта категория отображается пользователю и используется для поиска и фильтрации услуг."])},
    "typeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спецификация категории."])},
    "vatCategoryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эта категория скрыта от клиентов. Система выбирает процент НДС для товарной позиции в соответствии с этим выбором."])}
  },
  "serviceGroup": {
    "groupItemIsUnavailableForSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статья о продаже недоступна на основе выбора"])}
  },
  "serviceLabels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ярлыки сервиса"])},
  "serviceLocation": {
    "accessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметьте все варианты, которые работают."])},
    "addressInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск адреса использует поиск Google — введите свой адрес, так как вас можно найти в Google."])},
    "howToGetThereInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опишите под каким именем, как вас можно найти в картографических приложениях и/или маршрут от ближайшего к вам центра притяжения."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подведите мышку к нужному месту на карте и нажмите, появится булавка, при необходимости переместите булавку в нужное место."])},
    "selectLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите местоположение"])},
    "transportInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отметьте ближайшую остановку и расстояние от пункта назначения."])}
  },
  "serviceLocationInfo": {
    "coordinates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните координаты автоматически, нажав на опцию в поле «Искать адрес». Заполните их вручную или переместите булавку на карту и нажмите кнопку «Получить координаты карты»"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отображается в списке местоположений, чтобы помочь определить конкретные местоположения."])}
  },
  "serviceName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название статьи о продаже"])},
  "serviceNotSavedWarning": {
    "discard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить изменения"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы собираетесь продолжить без сохранения!"])}
  },
  "servicePeriod": {
    "activeHoursInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наличие товарных позиций по неделям и дням."])},
    "arrivalLengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность пребывания по прибытии"])},
    "lengthOfStayLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжительность пребывания"])},
    "periodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите период, в течение которого вы предлагаете товар. \nЕсли товар доступен круглый год, то сроком на календарный год и более. \nЕсли вы не хотите, чтобы период истекал, оставьте конец периода пустым."])},
    "restriction": {
      "info": {
        "arrivalLengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это минимальное и максимальное количество ночей, которые гости могут забронировать по прибытии в определенную дату. \nЭти ограничения должны быть пройдены в зависимости от даты прибытия периода бронирования."])},
        "bookingArrivalLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти ограничения эквивалентны минимальной и максимальной продолжительности пребывания на Booking.com с учетом ограничений по прибытии."])},
        "bookingLengthOfStay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эти ограничения эквивалентны ограничениям минимальной и максимальной продолжительности пребывания на Booking.com."])},
        "lengthOfStayDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это минимальное и максимальное количество ночей, которые гости могут забронировать. \nЭти ограничения должны быть соблюдены на все даты периода бронирования."])},
        "restrictionSectionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указанные ограничения будут применяться ко всем датам данного периода."])},
        "specifiedMaxRequirements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если указано, оно должно быть как минимум равно минимальным значениям как продолжительности пребывания, так и продолжительности пребывания, основанной на прибытии."])},
        "syncedToBookingGeneric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если интеграция активна и жилье подключено к номеру Booking.com, то это ограничение будет распространяться и на стоимость номера."])},
        "unspecifiedOptionBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если параметр не указан, ограничение не будет синхронизировано с Booking.com. \nЭто означает, что любое ранее установленное ограничение останется там активным."])},
        "unspecifiedOptionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если выбрана эта опция, ограничение не применяется."])},
        "unspecifiedOptionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неуказанный вариант"])}
      }
    },
    "restrictionsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничения"])},
    "stayOptionNotSpecified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неопределенные"])},
    "weekslotsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активные часы"])}
  },
  "servicePeriodList": {
    "addAnotherPeriodInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавьте новый период, если сезонно товар доступен в разное время суток."])}
  },
  "servicePeriodPricing": {
    "descriptionHelperInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название цены — это название цены, которое будет отображаться для конечного пользователя. \nОписание — это необязательное информативное поле, невидимое для конечного пользователя."])},
    "discountInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете выбрать, какую скидку вы хотите предложить, на каких условиях и в каком размере. При необходимости можно добавить несколько видов скидки."])},
    "identificatorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите уникальный идентификатор, который система использует для определения цены. \nНапример, это может быть название цены. \nНе используйте пробелы или специальные символы."])},
    "isMinimumPriceWarningAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К текущему выбору применена минимальная цена."])},
    "minPriceFieldLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная цена"])},
    "minPriceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если указано, заданное значение будет применяться как окончательная общая стоимость бронирования, если в противном случае первоначальная общая стоимость была бы ниже (включая скидки). \nНа стоимость дополнительных услуг не влияет минимальная цена. \nИх стоимость будет добавлена ​​после введения минимальной цены."])},
    "priceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите окончательную цену, включая налоги"])},
    "pricingPropertiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как рассчитывается цена товара для продажи на основе типа ценообразования:"])},
    "ticketCheckboxInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы отметите это, билет с QR-кодом для проверки будет создан и отправлен клиенту для каждого купленного товара."])}
  },
  "servicePreference": {
    "difficultyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если товар продажи имеет трудности, установите, если нет, не определено."])}
  },
  "servicePreferences": {
    "accessibilityInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбирайте то, что предлагается."])},
    "amenitiesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите дополнительные ценности, услуги, опции, включенные в цену."])},
    "contractInfo1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите договор, определяющий условия между клиентом и поставщиком услуг."])},
    "contractInfo2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить контракты можно в разделе «Настройки» — «Контракты»."])},
    "difficultyLabels": {
      "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Легкий"])},
      "hard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жесткий"])},
      "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Середина"])},
      "neutral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нейтральный"])}
    },
    "languagesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбирайте языки, на которых обслуживаются клиенты."])},
    "minReservationTimeInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите последнее время, когда можно приобрести билет. Если ограничений нет, оставьте поле пустым."])},
    "targetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если информация важна, выберите целевые группы, в противном случае оставьте поле пустым."])}
  },
  "servicePreview": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основе ранее введенной информации вид, который может видеть ваш клиент. Если все важное есть, нажмите кнопку публикации внизу страницы. Генерируется распространяемый URL/ссылка, которую вы можете разместить в своих каналах продаж (FB, домашняя страница, страницы партнеров...). До первой сделки купли-продажи все данные еще можно изменить. После первой продажи время и цена не могут быть изменены."])}
  },
  "servicePrivateStateFilter": {
    "allLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все"])},
    "privateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать приватное"])},
    "publicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать публично"])}
  },
  "serviceProvider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщик услуг"])},
  "serviceProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставщики услуг"])},
  "servicePublicURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общедоступный URL-адрес статьи о продаже:"])},
  "servicePublishButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публиковать"])},
  "serviceResource": {
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы используете планирование ресурсов, введите его, а если не используете, продолжайте."])},
    "newResourceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если нужный ресурс не был введен, это можно сделать здесь."])}
  },
  "serviceResources": {
    "removeResourceConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить этот ресурс?"])}
  },
  "serviceStateFilter": {
    "showActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать активные"])},
    "showAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать все"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать в архиве"])},
    "showUnpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать неопубликованные"])}
  },
  "serviceTabs": {
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Календарь"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи о продажах"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билеты"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])}
  },
  "serviceTemplates": {
    "addRelationsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите шаблоны электронных писем, которые вы хотите связать с данной статьей продаж."])},
    "addTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
    "createNewLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать новый шаблон электронной почты"])},
    "editRelationsLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношения шаблонов"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные шаблоны электронных писем"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["См. Настройки – Шаблоны писем для всех шаблонов. \nЗдесь вы можете добавить шаблоны к текущему элементу услуги."])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при сохранении отношений"])},
    "saveRelations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить отношения"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отношения успешно сохранены"])},
    "selectTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать отношения шаблона"])}
  },
  "serviceTicket": {
    "addAnotherTicket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить другой билет"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даты"])},
    "isActiveLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активно"])},
    "ticketTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билет"])},
    "updateTimelineTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить цены"])}
  },
  "serviceTimeline": {
    "tabTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билеты и цены"])},
    "timelineHeaderTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временная шкала"])}
  },
  "serviceType": {
    "eventLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
    "experienceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Услуга"])},
    "groupDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "groupLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "housingLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корпус"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип статьи о продаже"])},
    "moneyJarLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Денежная банка"])},
    "productDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите этот вариант, если хотите создать статью о продаже товара. Товары можно продавать напрямую или через другие статьи о продаже."])},
    "productLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товар"])},
    "salesItemLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товар для продажи"])},
    "ticketDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите этот вариант, если вы хотите продавать билеты."])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билет"])}
  },
  "serviceUnits": {
    "g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["G"])},
    "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час"])},
    "kg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КГ"])},
    "l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Л"])},
    "ml": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МЛ"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
    "otherUnitPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите единицу измерения"])},
    "pcs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ШТ"])},
    "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Человек"])},
    "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Питомец"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица"])}
  },
  "serviceView": {
    "duplicateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирование разрешено для опубликованных статей о продажах. \nВсе данные о товаре будут продублированы, кроме даты события (в случае события). \nДублирование продающей статьи не публикуется."])},
    "isArchivedLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивный"])},
    "isNotPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неопубликовано"])},
    "isPrivateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Товары, продаваемые по частным распродажам, не могут быть приобретены клиентами напрямую и не отображаются в качестве сопутствующих товаров."])},
    "isPrivateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частный"])},
    "isPublicLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный"])},
    "isPublishedInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликованная статья о продаже общедоступна."])},
    "isPublishedTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликовано"])},
    "publishError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось опубликовать статью о продаже."])},
    "publishSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статья о продаже успешно опубликована."])}
  },
  "service_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мероприятие"])},
  "service_periods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часы работы и цены"])},
  "service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажная статья"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статьи о продажах"])},
  "setDurationManually": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить продолжительность вручную"])},
  "setNewPassword": {
    "heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить новый пароль"])},
    "submitError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный токен или пароль"])},
    "tokenError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недействительный токен"])}
  },
  "setUserData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установить данные пользователя"])},
  "settings": {
    "ServicePublishWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чтобы опубликовать любую коммерческую статью, вы должны сначала заполнить следующую информацию:"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки"])},
    "tabLabel": {
      "bookingWidget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Публичный просмотр"])},
      "channelList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Каналы"])},
      "channelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер канала"])},
      "checkIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма регистрации"])},
      "companyEmails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления"])},
      "compucash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция с Compucash"])},
      "contracts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракты"])},
      "emailTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
      "googleCalendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Календарь"])},
      "inviteList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашения"])},
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение"])},
      "partnerList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Партнеры"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
      "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны"])},
      "trigon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigon"])},
      "userRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли пользователей"])}
    }
  },
  "settingsCheckIn": {
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать вопрос"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при удалении"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно удалено"])},
    "displayForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отобразить форму проверки"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лисакусимусед"])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительный вопрос"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вопрос"])}
  },
  "settingsContracts": {
    "archiveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при архивировании"])},
    "archiveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно заархивировано"])},
    "archivedSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Архивные статьи о продажах"])},
    "changeWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот контракт имеет связанные позиции заказа. \nПри изменении данных договора исходный договор будет заархивирован, а сервисные отношения будут перенесены."])},
    "clientConsents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные позиции заказа"])},
    "confirmArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите архивировать?"])},
    "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите удалить?"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать контракт"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракты"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присвойте контракт каждому товару. \nСогласие с условиями договора клиент подтвердит в процессе оформления заказа."])},
    "modalTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])},
    "relatedActiveSalesArticles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опубликованные статьи о продажах"])},
    "relatedPublishedServicesHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Похожие опубликованные статьи о продажах"])},
    "saveAsNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить как новый"])},
    "showArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать в архиве"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договор"])},
    "updatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновлено в"])}
  },
  "settingsLocation": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локации"])},
    "titleInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все местоположения поставщика услуг, которые можно присвоить товарам для продажи."])}
  },
  "settingsPayments": {
    "getFromAriregister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить из коммерческого регистра"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заполните обязательные поля название банка, имя получателя и IBAN, а затем нажмите кнопку «Сохранить» внизу справа."])},
    "noAriregisterData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет данных"])}
  },
  "settingsProfile": {
    "agreeToTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я прочитал и согласен с условиями веб-платформы"])},
    "basicInformationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все обязательные поля, кроме km obligee. Введите данные компании вручную или получите данные из коммерческого регистра."])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["контракт"])},
    "contractLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия договора между поставщиком услуг и Touringery"])},
    "descriptionInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В описании компании напишите миссию, слоган или важное сообщение, которое применимо ко всем предлагаемым статьям продаж."])},
    "locationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начните печатать в окне поиска. Мы используем поиск по адресу Google, поэтому введите, как вас можно найти в Google. Если вы все еще не можете найти точный адрес, вы можете вручную ввести округ, город и т. д."])},
    "socialNetworkAccountsInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите существующие, мы покажем их в представлении статьи о продаже рядом с каждой статьей о продаже."])},
    "vatNumberInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если км не является обязательным, оставьте поле пустым."])}
  },
  "settingsTemplates": {
    "contentPreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предварительный просмотр контента"])},
    "createInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создайте шаблон электронной почты, запланируйте время отправки и назначьте связанные службы"])},
    "createNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон"])},
    "createNewExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон услуги"])},
    "createNewHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать шаблон жилья"])},
    "deleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить шаблон"])},
    "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Демплата успешно удалена"])},
    "experienceDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество дней посещения"])},
    "experienceEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время окончания"])},
    "experienceStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата и время начала услуги"])},
    "forHousing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для жилья"])},
    "forService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для обслуживания"])},
    "housingDurationChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ночей посещения"])},
    "housingEndChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Самое позднее время выезда"])},
    "housingStartChipInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае проживания дата и диапазон времени заезда, в случае услуг дата и время начала мероприятия"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблоны электронной почты"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить автоматическую отправку электронных писем клиенту."])},
    "relatedServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связанные услуги"])},
    "relatedTimings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Соответствующие тайминги"])},
    "restrictionCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hе отправлять, если отправлена ​​форма регистрации."])},
    "restrictionCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применяет проверку отправки электронных писем: если клиент отправил форму регистрации, электронное письмо не будет отправлено."])},
    "restrictionNoCheckIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hе отправлять, если форма регистрации не отправлена"])},
    "restrictionNoCheckInInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применяет проверку отправки электронных писем: если клиент не отправил форму регистрации, электронное письмо не будет отправлено."])},
    "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить шаблон"])},
    "saveSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шаблон сохранен"])},
    "sendRetroactively": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить задним числом к ​​существующим бронированиям"])},
    "sendRetroactivelyInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите, если хотите отправить электронное письмо уже существующим бронированиям. \nЭлектронное письмо будет отправлено, если время в настройках шаблона электронного письма не прошло в отношении бронирования."])},
    "serviceCreateInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете настроить шаблоны электронной почты, относящиеся к этой служебной статье. \nЧтобы отредактировать все шаблоны электронных писем, перейдите в режим просмотра настроек."])},
    "serviceCreateWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имейте в виду, что если вы измените содержимое шаблона электронной почты, время или другие настройки, изменения будут применены ко всем статьям продаж, связанным с этим шаблоном."])},
    "serviceInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите релевантные продажи для этого шаблона"])},
    "timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сроки"])},
    "timingInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите время письма"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])}
  },
  "settingsUsers": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователи"])},
    "labelInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите имя пользователя и адрес электронной почты, установите роль и получите приглашение. После подтверждения сообщения, отправленного на электронную почту, новый пользователь может начать работу в соответствии с назначенной ролью."])},
    "tabs": {
      "companyRoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роли компании"])},
      "usersRignts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Права пользователей"])}
    },
    "userRolesInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Определите роли пользователей и разрешения ролей и назначьте каждому пользователю роль. \nПользователи могут видеть и редактировать только тот контент, который разрешен этой ролью."])}
  },
  "showAllAmenities": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Показать все ", _interpolate(_named("numOfAmenities")), " удобства"])},
  "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показывай меньше"])},
  "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Показать больше"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
  "socialNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Аккаунты в социальных сетях"])},
  "somethingFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Что-то не удалось"])},
  "specialRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особые запросы"])},
  "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Особые запросы"])},
  "specifyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Укажите адрес"])},
  "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начинать"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала"])},
  "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние"])},
  "statisticsReport": {
    "companyInformation": {
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название организации"])},
      "organizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Организация"])}
    }
  },
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улица"])},
  "subcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подкатегория"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резюме"])},
  "supervisor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руководитель"])},
  "support": {
    "card": {
      "title": {
        "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжитесь с нами"])},
        "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить сообщение"])}
      }
    },
    "contactHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с 9:00 до 17:00"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
    "facebookGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["группа в Фейсбуке"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддерживать"])},
    "supportExplanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляя эту форму, вы отправляете письмо в службу поддержки сайта Touringery. \nЦель этой формы — оставить отзыв и сообщить о технических проблемах на сайте."])},
    "tabLabel": {
      "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакт"])},
      "helpCenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр помощи"])}
    },
    "tel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])}
  },
  "switchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить компанию"])},
  "tabs": {
    "servicePeriodsForEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])}
  },
  "takeMeThere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Возьми меня туда"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача"])},
  "taskAcceptSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача принята"])},
  "taskColor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет задачи"])},
  "taskColorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цвет задачи представляет собой цвет карточки задачи в представлении календаря. \nЧто улучшает контрастность и делает календарь более читаемым."])},
  "taskCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача успешно создана"])},
  "taskCreationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось создать задачу"])},
  "taskDeleteError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить задачу"])},
  "taskDeletedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача успешно удалена"])},
  "taskDurationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Длительность задачи доступна только в том случае, если для типа задачи задан открытый временной интервал. \nПродолжительность задачи позволяет определить продолжительность за вычетом разницы между началом и сроком, чтобы получатель мог выполнить задачу, когда есть свободное время для ее выполнения."])},
  "taskEndedAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача завершена и больше не может быть изменена"])},
  "taskGeneratedCreatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматизированная задача '", _interpolate(_named("taskTitle")), "' успешно создана"])},
  "taskGeneratedCreationError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось создать автоматическую задачу '", _interpolate(_named("taskTitle")), "'"])},
  "taskGeneratedDeleteError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось удалить автоматическую задачу '", _interpolate(_named("taskTitle")), "'"])},
  "taskGeneratedDeleteSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматизированная задача '", _interpolate(_named("taskTitle")), "' успешно удалена"])},
  "taskGeneratedUpdateError": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Не удалось обновить автоматическую задачу '", _interpolate(_named("taskTitle")), "'"])},
  "taskGeneratedUpdatedSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Автоматизированная задача '", _interpolate(_named("taskTitle")), "' успешно создана"])},
  "taskGenerator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Автоматизированные задачи"])},
  "taskGeneratorInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Здесь вы можете определить задачи, которые автоматически генерируются заданными переменными."])},
  "taskHasEnded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача завершена"])},
  "taskInformationSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о задаче сохраняется"])},
  "taskInformationSavedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить информацию о задаче"])},
  "taskMarkedAsDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача помечена как выполненная"])},
  "taskMarkedAsNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача помечена как не выполненная"])},
  "taskPeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период задачи"])},
  "taskRefuseSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задача отклонена"])},
  "taskRefuseTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отказаться от задания?"])},
  "taskReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напоминание получателю"])},
  "taskReminderInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время напоминания о задаче напоминает получателю по электронной почте о предстоящей задаче"])},
  "taskWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предупреждение руководителю"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Задания"])},
  "teamPermissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Разрешения для команды"])},
  "termsOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия обслуживания"])},
  "textEditor": {
    "enterLinkLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["введите ссылку"])}
  },
  "thisMonthResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты этого месяца"])},
  "ticket": {
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупатель"])},
    "fetchTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при получении данных тикета"])},
    "invalidScannedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Содержимое отсканированного кода имеет неожиданный формат"])},
    "notValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не проверено"])},
    "paidAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплачено в"])},
    "ticketLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билет"])},
    "tickets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билеты"])},
    "ticketsValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
    "unValidateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка билета успешно приостановлена"])},
    "unvalidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус подтверждения переключения"])},
    "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно с"])},
    "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действительно до"])},
    "validateTicketError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка проверки билета"])},
    "validateTicketSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Билет успешно подтвержден"])},
    "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утверждено"])},
    "validatedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время проверки"])},
    "validatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Утверждено"])},
    "validationInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка"])},
    "validationState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Состояние проверки"])},
    "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Период действия"])}
  },
  "ticketValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка прошла успешно"])},
  "ticketValidationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка не удалась"])},
  "ticketValidity": {
    "disclaimer": {
      "daysFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["дней с момента покупки"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["до"])},
      "valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годен до"])},
      "validDuringWholeTimeslot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пилет действителен в течение всего выбранного временного интервала."])},
      "validFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действителен с"])},
      "validUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Годен до"])},
      "validUntilIsLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Билет действителен в течение ", _interpolate(_named("duration")), " с момента активации или до окончания временного интервала, в зависимости от того, что наступит раньше."])},
      "validUntilNotLimitedByEndDynamic": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Билет действителен в течение ", _interpolate(_named("duration")), " с момента активации."])}
    },
    "fixedDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фиксированные даты"])},
    "fromUntilDates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Даты начала и окончания"])},
    "infoHelper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы определите период действия цены, эти данные будут присутствовать на билетах данного периода времени. \nВы можете указать форму дат и до или установить период времени, начиная с момента покупки."])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок действия цены"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неопределенный"])},
    "periodFromPurchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок с момента покупки"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип периода действия"])}
  },
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К"])},
  "toDetailView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть"])},
  "total": {
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "payBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К возврату"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К оплате"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий"])},
    "totalWithDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего со скидкой"])},
    "totalWithoutVat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итого без НДС"])}
  },
  "totalPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Итоговая цена"])},
  "totalTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доход от реализации услуг по размещению"])},
  "travelPurpose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель путешествия"])},
  "travelingToDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Путешествие к месту назначения"])},
  "travellersCountry": {
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гражданство путешественника"])},
    "countryCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код страны"])},
    "housingOvernight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество путешественников за ночь"])},
    "roomsSold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество путешественников"])}
  },
  "travellingPurposesTableHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Распределение размещаемых лиц по цели поездки"])},
  "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Курок"])},
  "trigon": {
    "checkBoxLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция с Trigon включена"])},
    "emailPrefix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уникальная электронная почта для счетов Trigon"])},
    "helperText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Включив интеграцию с Trigon, копия всех email-сообщений с счетами будет автоматически отправляться в Trigon, где счета будут оцифрованы для более удобной обработки. Вы получите email с уникальным идентификатором от Trigon, куда Touringery будет отправлять все счета."])},
    "settingChangeError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка при сохранении настройки"])},
    "settingChangedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка успешно сохранена"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Интеграция с Trigon"])}
  },
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
  "types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Типы"])},
  "unCheckAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снять все"])},
  "unauthorized": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не авторизованы для доступа к этому представлению"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неавторизованный"])}
  },
  "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица"])},
  "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
  "upcoming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Предстоящие"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновлять"])},
  "updateCartItemError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка обновления корзины"])},
  "updateCartItemSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корзина успешно обновлена"])},
  "updateInCart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить корзину"])},
  "uploadNewAvatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить новый аватар"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь"])},
  "userNotAssociatedWithCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пользователь не связан с компанией"])},
  "userRoleSaveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось сохранить роль пользователя"])},
  "userRoleSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Роль пользователя успешно сохранена"])},
  "vacation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отпуск"])},
  "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НДС"])},
  "vatCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Категория НДС"])},
  "vat_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер НДС"])},
  "visitor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["посетитель"])},
  "visitors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["посетители"])},
  "warningInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время предупреждения о задаче предупреждает получателя и супервайзера по электронной почте о невыполненной задаче."])},
  "week": {
    "friday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пятница"])},
    "monday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понедельник"])},
    "saturday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Суббота"])},
    "sunday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воскресенье"])},
    "thursday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Четверг"])},
    "tuesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вторник"])},
    "wednesday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среда"])}
  },
  "weekSlots": {
    "buttons": {
      "setClear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пустой"])},
      "setNineToFive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["9-17 рабочих дней"])},
      "setTenToEight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10-20 каждый день"])},
      "setWholeWeek ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всю неделю"])}
    }
  },
  "when": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда"])},
  "whenAreYouGoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Когда вы собираетесь?"])},
  "where": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Где"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Работа"])},
  "wrongUserNameOrPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный логин или пароль!"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your-profile"])},
  "zipCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почтовый индекс"])}
}