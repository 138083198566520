import PublicLayout from '@/layouts/PublicLayout.vue'
import WidgetLayout from '@/layouts/WidgetLayout.vue'
import { OrderItemReferral } from '@/models/public/order.model'
import { ROUTE_PATHS } from '@/router/public/paths'
import { RouteRecordRaw } from 'vue-router'

const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: ROUTE_PATHS.CART,
    name: ROUTE_PATHS.CART,
    component: () => import('@/views/public/CartView.vue'),
    props: (route) => ({
      orderUuid: route.params.uuid,
      showAsView: true,
    }),
    beforeEnter: [],
    meta: { layout: PublicLayout },
    alias: ROUTE_PATHS.ORDER,
  },
  {
    path: ROUTE_PATHS.EDIT_CART,
    name: ROUTE_PATHS.EDIT_CART,
    component: () => import('@/views/public/EditCartView.vue'),
    props: (route) => ({ orderUuid: route.params.uuid }),
    meta: { layout: PublicLayout },
    alias: ROUTE_PATHS.EDIT_ORDER,
  },
  {
    path: ROUTE_PATHS.DETAILS,
    name: ROUTE_PATHS.DETAILS,
    component: () => import('@/views/public/DetailsView.vue'),
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.PAYMENT,
    name: ROUTE_PATHS.PAYMENT,
    component: () => import('@/views/public/PaymentView.vue'),
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.EDIT_PAYMENT,
    name: ROUTE_PATHS.EDIT_PAYMENT,
    component: () => import('@/views/public/EditCartPaymentView.vue'),
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.TERMS,
    name: ROUTE_PATHS.TERMS,
    component: () => import('@/views/public/TermsView.vue'),
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.PRIVACY_POLICY,
    name: ROUTE_PATHS.PRIVACY_POLICY,
    component: () => import('@/views/public/PrivacyPolicy.vue'),
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.SERVICES,
    name: ROUTE_PATHS.SERVICES,
    component: () => import('@/views/public/ServiceView.vue'),
    props: (route) => ({
      serviceId: route.params?.id,
      referral: {
        referral_type: route.query.ref_type ?? '',
        referral_code: route.query.ref_code ?? '',
      } as OrderItemReferral,
    }),
    beforeEnter: [],
    meta: {
      layout: PublicLayout,
      serviceProviderThemeEnabled: true,
      serviceProviderThemeRouteParam: 'id',
    },
  },
  {
    path: ROUTE_PATHS.ORDER_ITEM_CHECK_IN,
    name: ROUTE_PATHS.ORDER_ITEM_CHECK_IN,
    component: () => import('@/views/public/CheckInView.vue'),
    props: true,
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.CHECK_IN,
    name: ROUTE_PATHS.CHECK_IN,
    component: () => import('@/views/public/CheckInView.vue'),
    props: true,
    beforeEnter: [],
    meta: { layout: PublicLayout },
  },
  {
    path: ROUTE_PATHS.BOOKING_WIDGET,
    name: ROUTE_PATHS.BOOKING_WIDGET,
    component: () => import('@/views/public/BookingWidgetView.vue'),
    props: (route) => ({
      companyToken: route.query.token ?? '',
    }),
    beforeEnter: (to, from, next) => {
      const token = new URLSearchParams(window.location.search).get('token')
      if (!token) {
        return next(false) // Redirect or show error if needed
      }
      next()
    },
    meta: { layout: WidgetLayout },
  },
]

export default publicRoutes
