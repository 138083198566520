import {
  CancelOrder,
  CreateEventServiceOrderItem,
  CreateExperienceServiceOrderItem,
  CreateGroupServiceOrderItem,
  CreateHousingServiceOrderItem,
  CreateProductServiceOrderItem,
  CreateSimpleServiceOrderItem,
  Order,
  OrderClient,
  OrderConfirmation,
} from '@/models/public/order.model'
import axios from 'axios'
import { Ref } from 'vue'
import { useMutation, useQuery } from 'vue-query'

export const cartKeys = {
  all: ['cartKeys'] as const,
  details: () => [...cartKeys.all, 'detail'] as const,
  detail: (id: number) => [...cartKeys.details(), id] as const,
  confirmations: () => [...cartKeys.all, 'confirmation'] as const,
  confirmation: (id: string) => [...cartKeys.confirmations(), id] as const,
}

export function useFetchCart(uuid: string | null) {
  return useMutation(
    async () => {
      const { data } = await axios.get<Order>(`order/${uuid}/`)
      return data
    },
    {
      retry: 0,
    },
  )
}

export function useFetchCartByUuid() {
  return useMutation(
    async (uuid: string) => {
      const { data } = await axios.get<Order>(`order/${uuid}/`)
      return data
    },
    {
      retry: 0,
    },
  )
}

export function useCreateOrderMutation() {
  return useMutation(async () => {
    const { data } = await axios.post<Order>(`order/`)
    return data
  })
}

export function createOrUpdateEventServiceOrderItem(
  orderUuid: string,
  payload: CreateEventServiceOrderItem,
) {
  return axios.put<Order>(`order/${orderUuid}/event-service-item/`, payload)
}

export function createOrUpdateExperienceServiceOrderItem(
  orderUuid: string,
  payload: CreateExperienceServiceOrderItem,
) {
  return axios.put<Order>(
    `order/${orderUuid}/experience-service-item/`,
    payload,
  )
}

export function updateExperienceServiceOrderItem(
  orderUuid: string,
  orderItemId: number,
  payload: CreateExperienceServiceOrderItem,
) {
  return axios.put<Order>(
    `order/${orderUuid}/experience-service-item/${orderItemId}/`,
    payload,
  )
}

export function createOrUpdateProductOrderItem(
  orderUuid: string,
  payload: CreateProductServiceOrderItem,
) {
  return axios.put<Order>(`order/${orderUuid}/product-service-item/`, payload)
}

export function createOrUpdateSimpleServiceOrderItem(
  orderUuid: string,
  payload: CreateSimpleServiceOrderItem,
) {
  return axios.put<Order>(`order/${orderUuid}/simple-service-item/`, payload)
}

export function createHousingServiceOrderItem(
  orderUuid: string,
  payload: CreateHousingServiceOrderItem,
) {
  return axios.put<Order>(`order/${orderUuid}/housing-service-item/`, payload)
}

export function createOrUpdateTicketOrderItem(
  orderUuid: string,
  payload: CreateGroupServiceOrderItem,
) {
  // TODO: TO-809: Create endpoint
  return axios.put<Order>(`order/${orderUuid}/ticket-service-item/`, payload)
}

export function createOrUpdateGroupOrderItem(
  orderUuid: string,
  payload: CreateGroupServiceOrderItem,
) {
  return axios.put<Order>(`order/${orderUuid}/group-service-item/`, payload)
}

export function updateHousingServiceOrderItem(
  orderUuid: string,
  orderItemId: number,
  payload: CreateHousingServiceOrderItem,
) {
  return axios.put<Order>(
    `order/${orderUuid}/housing-service-item/${orderItemId}`,
    payload,
  )
}

export function updateCancelOrder(orderUuid: string) {
  return axios
    .put<Order>(`order/${orderUuid}/cancel/`)
    .then((response) => response.data)
}

export function updateDuplicateOrder(orderUuid: string) {
  return axios
    .put<Order>(`order/${orderUuid}/duplicate/`)
    .then((response) => response.data)
}

export function useOrderConfirmationsMutation(orderUuid: Ref<string>) {
  return useQuery(cartKeys.confirmation(orderUuid.value), async () => {
    const { data } = await axios.get<OrderConfirmation[]>(
      `order/${orderUuid.value}/confirmation/`,
    )
    return data
  })
}

export function updateOrderConfirmations(
  orderUuid: string,
  body: { company: number | undefined; agreed: boolean },
) {
  return axios
    .put<OrderConfirmation[]>(`order/${orderUuid}/confirmation/`, body)
    .then((response) => response.data)
}

export function deleteRemoveOrderItemGroup(
  orderUuid: string,
  orderGroupId: number,
) {
  return axios
    .delete<Order>(`order/${orderUuid}/remove-group/${orderGroupId}/`)
    .then((response) => response.data)
}

export function deleteRemoveOrderItem(orderUuid: string, orderItemId: number) {
  return axios
    .delete<Order>(`order/${orderUuid}/remove-item/${orderItemId}/`)
    .then((response) => response.data)
}

export function updateConfirmOrderCart(orderUuid: string) {
  /** Finalize cart and proceed to client information phase */
  return axios
    .put<Order>(`order/${orderUuid}/confirm-cart/`)
    .then((response) => response.data)
}

export function updateConfirmOrderClient(orderUuid: string) {
  /** Finalize client information and proceed to payment phase */
  return axios
    .put<Order>(`order/${orderUuid}/confirm-client/`)
    .then((response) => response.data)
}

export function updateConfirmEditedCart(orderUuid: string) {
  /** Finalize client information and proceed to payment phase */
  return axios
    .put<Order>(`order/${orderUuid}/confirm-edited-cart/`)
    .then((response) => response.data)
}

export function useUpdateCartMutation(orderUuid: string) {
  return useMutation(async (order: Order) => {
    const { data } = await axios.put<Order>(`order/${orderUuid}/cart/`, order)
    return data
  })
}

export function useUpdateOrderClientMutation(orderUuid: string) {
  return useMutation(async (client: OrderClient) => {
    const { data } = await axios.put<Order>(`order/${orderUuid}/client/`, {
      client: client,
    })
    return data
  })
}

export function useUpdateOrderCommentMutation() {
  return useMutation(
    async ({ comment, uuid }: { comment: string; uuid: string }) => {
      const { data } = await axios.put<Order>(`order/${uuid}/comment/`, {
        comment,
      })
      return data
    },
  )
}

export function useUpdateOrderExpiresAtMutation() {
  return useMutation(
    async ({ expires_at, uuid }: { expires_at: string; uuid: string }) => {
      const { data } = await axios.put<Order>(`order/${uuid}/expires-at/`, {
        expires_at,
      })
      return data
    },
  )
}

export type InitiatePaymentPayload = Pick<Order, 'payment_method' | 'bank'>

export function useInitiateOrderPaymentMutation(orderUuid: string) {
  return useMutation(async (payload: InitiatePaymentPayload) => {
    const { data } = await axios.put<Order>(
      `order/${orderUuid}/initiate-payment/`,
      payload,
    )
    return data
  })
}

export function useCreateTransactionMutation() {
  return useMutation(async (orderId: number) => {
    const { data } = await axios.post<Order>(
      'maksekeskus/create-transaction/',
      {
        order: orderId,
      },
    )
    return data
  })
}

// TODO: Deprecated
export function useCancelPaidOrderMutation(orderUuid: string | string[]) {
  return useMutation(async (payload: CancelOrder) => {
    const { data } = await axios.put<CancelOrder>(
      `order/${orderUuid}/cancel-order/`,
      payload,
    )

    return data
  })
}
